import React from 'react'
import Mcabanner from './Mcabanner/Mcabanner'
import Onlinemca from './Onlinemca/Onlinemca'
import Mcacard from './Mcacard/Mcacard'
import SmallBanMca from './SmallBanMca/SmallBanMca'
import ReasonMca from './Mcacard/ReasonMca/ReasonMca'
import DiscoverMca from './DiscoverMca/DiscoverMca'
import QualifiedMca from './QuaalifiedMca/QualifiedMca'

function mca() {
  return (
   <>
   <Mcabanner/>
   <Onlinemca/>
   <Mcacard/>
   {/* <SmallBanMca/> */}
   <ReasonMca/>
   <DiscoverMca/>
   <QualifiedMca/>
   </>
  )
}

export default mca