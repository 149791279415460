import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import "./BhartiFaq.css";
import parse from "html-react-parser";

const BhartiFaq = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/bharati-vidyapeeth/all-faq`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="bharti-question-section ">
        <Container>
          <div className="heading">
            <h4 className="title">Frequently Asked Questions</h4>
            <div className="text-border" />
          </div>

          <div className="questions-sec">
            <Accordion defaultActiveKey="0">
              {data?.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{item?.name}</Accordion.Header>
                  <Accordion.Body>
                    <p>{parse(item?.description)}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BhartiFaq;
