import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./Diplomaonline.css";
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
import { Modal } from "react-bootstrap";
const DiplomaOnline = () => {
  const { getData, IMG_URL } = useContext(Context);
       // become partner modal
       const [show, setShow] = useState(false);
       const handleClose = () => setShow(false);
       const handleShow = () => setShow(true);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/online`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="diploma-online">
        <div className="container">
          {data?.map((val) => (
            <div className="row">
              <div className="col-md-9">
                <div className="heading-holder">
                  <h4 className="title">{val?.name}</h4>
                  <p>{parse(val.description)}</p>
                </div>
              </div>
            </div>
          ))}
          <div className='modal-btn-div'>
            <button className='modal-apply-now' onClick={handleShow}>Apply Now</button>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Become A Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BecomePartner />
            </Modal.Body>
          </Modal>
      </section>
    </>
  );
};

export default DiplomaOnline;
