import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./ProgrammFeatured.css";
import parse from "html-react-parser";
const ProgrammFeatured = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/program-features`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="programm-featured">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 text-center">
              <div className="title-programm-features">
                <h3 className="title">
                  {" "}
                  Key P<span>rogram Fea</span>tures
                </h3>
                <p>
                  An enriching learning experience should be engaging and
                  stimulating.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5 justify-content-center">
            {data?.map((val) => (
              <div className="col-md-2 mx-auto col-12">
                <div className="grey-box-main">
                  <div className="grey-box">
                    <p className="features-name">{val?.name}</p>
                  </div>
                  <div className="orange-box-bottom-border"></div>
                  <div className="strategising-text slide-bottom">
                    <p>{parse(val?.description)}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className='col-md-2  mx-auto col-12 '>
                            <div className='grey-box-main'>
                                <div className='grey-box'>
                                    <p className='features-name'>Leadership</p>
                                </div>
                                <div className='orange-box-bottom-border'>
                                </div>
                                <div className='strategising-text slide-bottom'>
                                    <p>Learn how to develop workplace strategies that enhance technology and people to build sustainable ecosystems</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2  mx-auto col-12'>
                            <div className='grey-box-main'>
                                <div className='grey-box'>
                                    <p className='features-name-analyses'>Analyses</p>
                                </div>
                                <div className='orange-box-bottom-border'>
                                </div>
                                <div className='strategising-text slide-bottom'>
                                    <p>Learn how to develop workplace strategies that enhance technology and people to build sustainable ecosystems</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2  mx-auto col-12'>
                            <div className='grey-box-main'>
                                <div className='grey-box'>
                                    <p className='features-name'>Influencing</p>
                                </div>
                                <div className='orange-box-bottom-border'>
                                </div>
                                <div className='strategising-text slide-bottom'>
                                    <p>Learn how to develop workplace strategies that enhance technology and people to build sustainable ecosystems</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2  mx-auto col-12'>
                            <div className='grey-box-main'>
                                <div className='grey-box'>
                                    <p className='features-name-problem'>Problem Solving</p>
                                </div>
                                <div className='orange-box-bottom-border'>
                                </div>
                                <div className='strategising-text slide-bottom'>
                                    <p>Learn how to develop workplace strategies that enhance technology and people to build sustainable ecosystems</p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgrammFeatured;
