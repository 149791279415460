import React, { useContext, useEffect, useState } from "react";
import "./SuggestedCourses.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faRibbon, faStar } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { suggestedCourses } from "../../../../utils/apis/common/Common";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { ShimmerThumbnail } from "react-shimmer-effects";

function SuggestedCourses({ id }) {
  const { IMG_URL, getData } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState([]);

  useEffect(() => {
    const getDataAll = async () => {
      try {
        const response1 = await getData(`/without-login/ed-tech/home/university-specilization/${id}`);
        const cu_id = response1?.data?.cource_university?.id;
        await getSuggestedCourses(cu_id);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    getDataAll();
  }, [id, getData]);

  const getSuggestedCourses = async (cu_id) => {
    try {
      const res = await suggestedCourses(cu_id);
      if (res?.success) {
        setCourse(res?.data);
      }
    } catch (error) {
      console.error("Error fetching suggested courses:", error);
    }
  };

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  return (
    <>
      {loading &&
        <ShimmerThumbnail height={250} rounded />
      }
      {!loading && course && course?.length > 0 && (
        <section className="suggest_course_section">
          <Container>
            <div className="suggestcourse_main">
              <div className="title_suggest mb-3">
                <h2 className="suggtitle"> Suggested Courses</h2>
              </div>
              <Row>
                {course?.map((item) => (
                  <Col xxl={3} xl={3} lg={4} md={6} sm={6} className="mb-3" key={item.id}>
                    <div className="suggest_card">
                      <div className="suggest_imagemain_div">
                        <div className="sugggest_image">
                          <img className="sugg_img" src={IMG_URL + item?.specialization_banner?.image} alt="" />
                        </div>
                        <div className="clg_bgrd"></div>
                      </div>
                      <div className="clgname_div">
                        <p className="clgname_p">{item?.location}</p>
                      </div>
                      <div className="card_text">
                        <p className="coursename"> {item?.name}</p>
                        <p className="degreename">
                          <FontAwesomeIcon icon={faRibbon} className="me-2 iconname" />
                          {item?.cource_university?.ed_tech_course?.course_type?.name}
                        </p>
                        <p className="degreename">
                          <FontAwesomeIcon icon={faClock} className="me-2 iconname" />
                          {item?.cource_university?.ed_tech_course?.years ? `${item.cource_university.ed_tech_course.years} years` : "N/A"}
                        </p>
                        {item?.specialization_with_onlines && item?.specialization_with_onlines[0]?.description && (
                          <div className="d-flex">
                            <FontAwesomeIcon icon={faStar} className="me-2 iconname" />
                            <p className="degreename">{htmlToReactParser.parse(item?.specialization_with_onlines[0]?.description)}</p>
                          </div>
                        )}
                        <div className="button_main_div pt-2">
                          <Row>
                            <Col className="px-2">
                              <div className="">
                                <Link 
                                  to={`/ed-tech/specialization/${item?.id}/${item.name.replace(/\s+/g, '-').toLowerCase()}`}
                                onClick={window.scrollTo(0, 0)}
                                >
                                  <button type="button" className="view_program_btn">
                                    View Program
                                  </button>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}

export default SuggestedCourses;
