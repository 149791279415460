import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import "./admissionprocedure.css";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import parse from "html-react-parser";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerSectionHeader } from "react-shimmer-effects";

const AdmissionProcedure = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/admission/admission-steps`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {   
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); 

    return () => clearTimeout(timer); 
  }, []);

  return (
    <>
      <section className="admission-procedure">
        <Container>
          <Row>
            {isLoading ? (
              <ShimmerSectionHeader center />
            ) : (
              <Col md={8} className="mx-auto">
                <div className="text-holder-procedure mb-md-5 mb-3">
                  <h2>Below are the steps for the admission procedure.</h2>
                  <p>
                    You can fill out the form on the right-hand side to get free career counselling. After filling out the form our counsellor will call you and give you proper guidance regarding the course most suitable for your career growth and guide you through the admission process of the particular university you wish to apply for. After applying for the required course in the desired university you can pay the fees by choosing from the multiple fee options available.
                  </p>
                </div>
              </Col>
            )}
          </Row>
          {!isLoading && ( 
            <div className="row step-swiper">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                modules={[Pagination]}
                className=""
                breakpoints={{
                  0: { slidesPerView: 1, spaceBetween: 10 },
                  360: { slidesPerView: 1, spaceBetween: 10 },
                  576: { slidesPerView: 2, spaceBetween: 10 },
                  640: { slidesPerView: 2, spaceBetween: 10 },
                  768: { slidesPerView: 2, spaceBetween: 40 },
                  1024: { slidesPerView: 3, spaceBetween: 40 },
                  1400: { slidesPerView: 4, spaceBetween: 40 },
                  1800: { slidesPerView: 4, spaceBetween: 40 },
                  2500: { slidesPerView: 4, spaceBetween: 40 },
                }}
              >
                {data?.map((step, index) => (
                  <SwiperSlide key={index}>
                    <div className="col-md-12">
                      <Card
                        className="main-card"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <Card.Body>
                          <Card.Title>
                            <h2 className="step-title">{step?.name}</h2>
                          </Card.Title>
                          <Card.Text>
                            <p className="card-text">
                              {parse(step?.description) || " "}
                            </p>
                          </Card.Text>
                        </Card.Body>
                        <div className="orange-bg">
                          <img
                            src={IMG_URL + step?.image}
                            className="card-img-class"
                            alt="..."
                          />
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default AdmissionProcedure;
