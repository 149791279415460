import BcaAdvantage from "./BcaAdvantage/BcaAdvantage"
import BcaBanner from "./BcaBanner/BcaBanner"
import BcaDifference from "./BcaDifference/BcaDifference"
import OnlineBca from "./OnlineBca/OnlineBca"
import Who_qualified from "./who-qualified/Who_qualified"
import Reason from "./Reason/Reason"
import SmallBannerBca from "./Small-banner-bca/SmallBannerBca"


const Bca = () => {
  return (
    <>
      <section className='Bca'>
        <BcaBanner />
        <OnlineBca />
        <Reason />
        <BcaAdvantage />
        <BcaDifference />
        <SmallBannerBca />
        <Who_qualified />

      </section>

    </>
  )
}

export default Bca