import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from '../../../../../Utils/MyContext/MyContext';
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import "./DifferenceMba.css"

const DifferenceMba = () => {
  const cardsContent = [
    {
      title: "Flexibility Redefined",
      description: "Our Online MBA offers unparalleled flexibility, enabling you to balance your professional commitments seamlessly. With the freedom to choose when and where you study, you're in control of your educational journey."
    },
    {
      title: "Work-Integrated Learning",
      description: "Immerse yourself in real-world appli-cations as you study. Our Online MBA is designed to complement your work experience, allowing you to immediately apply what you learn to your professional role."
    },
    {
      title: "Cost-Effective Solutions",
      description: "Say goodbye to relocation costs and commuting hassles. Our Online MBA allows you to pursue advanced education without the financial strain associated with traditional, on-campus programs."
    },
    {
      title: "Innovative Technology",
      description: "Harness the power of cutting-edge online tools and platforms. Our program integrates innovative technology for a dynamic, interactive learning experience, bringing the classroom to you wherever you are."
    },
    {
      title: "Global Networking Opportunities",
      description: "Connect with a diverse group of professionals from around the globe. Our online platform fosters a rich, collaborative environment, expanding your network and exposing you to a variety of perspectives."
    },
    {
      title: "Customized Learning Experience",
      description: "Tailor your learning journey to fit your specific career goals. Our Online MBA program offers customizable options, allowing you to focus on areas that align with your professional aspirations."
    },
    {
      title: "Career Advancement on Your Terms",
      description: "Elevate your career at your pace. With our Online MBA, you have the flexibility to continue working while pursuing advanced qualifications & positioning yourself for promotions & leadership roles."
    },
    // Add more objects as needed for additional cards
  ];
  const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mba/discover`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className='Difference'>
        <div className='container'>
          <h2 className='difference_heading'>Discover the Power of Online MBA</h2>
          <div className='row mb-5 justify-content-center'>

            {data?.map((card, index) => (
              <div className='col-lg-3 col-md-6' key={index}>
                <div className='card mb-3 differenceCard'>
                  <div className='card-body'>
                    <p>{parse(card?.description) || ""}</p>
                    <div className='cardBelowtxt'>
                      <span>{card.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>


      </section>
    </>
  )
}

export default DifferenceMba