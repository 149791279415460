import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import "../../Bcom/banner/bcombanner.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const BlibBanner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-lib/banner`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="bcom-banner">
        <div className="container">
          <div className="bcom-banner-div desktop-view-banner">
            <div className="image-div">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="image-div">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="bca-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="bcom-banner-div mobile-view-banner">
            <div className="image-div">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="image-div">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="bca-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlibBanner;
