import React, { useContext, useState, useEffect } from "react";
import ImageContext from "../../../Utils/MyContext/MyContext";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './BecomePartnerSlider.css'
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
const BecomePartnerSlider = () => {


    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        const response = await getData(
            `/without-login/ed-tech/university/common/all-university`
        );
        await setData(response?.data?.data);
    };

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        AOS.init({ once: true });
    }, []);
    return (
        <>

            <section className='head-partner-slider'>
                <div className='container'>
                    <h1 className='title '>Our Associates</h1>
                    <div data-aos="fade-up" data-aos-duration="3000">
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 2,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                991: {
                                    slidesPerView: 5,
                                },
                            }}
                            className="mySwiper"
                        >
                            {data?.map((image, index) => (
                                <SwiperSlide>
                                    <Link
                                        // to={`/ed-tech/university/university/${image?.id}`}
                                        to={`/ed-tech/university/${image.id}/${image.name.replace(/\s+/g, '-').toLowerCase()}`}
                                    >
                                        <div className='become-slider'>
                                            <img
                                                key={image.id}
                                                src={IMG_URL + image?.image}
                                                className="bca-img"
                                            />
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                </div>
            </section >

        </>
    )
}

export default BecomePartnerSlider