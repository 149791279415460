import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './DiscoverMa.css'
import Card from 'react-bootstrap/Card';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination"
import "swiper/swiper-bundle.css"
import 'swiper/css'
const DiscoverMa = () => {
    const DiscoverSlider = [
        {
            title: 'Flexibility Redefined',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-1.png",
            description: 'Explore the benefits of our online M.A., designed for working professionals. Enjoy the freedom to study at your own pace, balancing your career and education seamlessly.',
        },
        {
            title: 'Work-Integrated Learning',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-2.png",
            description: 'Our online M.A. program seamlessly integrates with your professional life, offering practical, real-world applications that enhance your skills and knowledge in your field.',
        },
        {
            title: 'Cost-Effective Solutions',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-3.png",
            description: 'Experience an affordable path to an M.A. without compromising on quality. Our online program minimizes expenses associated with traditional education, making it a smart choice for budget-conscious professionals.',

        },
        {
            title: 'Innovative Technology',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-4.png",
            description: 'Immerse yourself in a cutting-edge learning environment. Our online M.A. leverages innovative technology, ensuring a dynamic and engaging educational experience that prepares you for the future.',
        },
        {
            title: 'Global Networking Opportunities',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-5.png",
            description: 'Connect with a diverse community of professionals worldwide. Our online M.A. opens doors to global networking, fostering valuable connections that transcend geographical boundaries.',
        },
        {
            title: 'Customized Learning Experience',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-6.png",
            description: 'Tailor your learning journey to match your career goals. Our online M.A. provides a personalized experience, allowing you to focus on areas that align with your aspirations and interests.',

        },
        {
            title: 'Career Advancement On Your Terms',
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/difrrent-7.png",
            description: 'Achieve career milestones on your terms. With our online M.A., you can advance your career without interrupting your professional commitments, creating a seamless path to success.',
        },

    ]


    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/pg-courses/ma/discover`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='Madiscover-power'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder text-center mb-md-5 mb-sm-4 mb-3'>
                            {/* <h3 className='title'>Discover the Power of Online M.A.</h3> */}

                        </div>
                        <Swiper
                            modules={[Pagination]}
                            spaceBetween={20}

                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                360: {
                                    slidesPerView: 1,
                                },
                                467: {
                                    slidesPerView: 1,
                                },
                                567: {
                                    slidesPerView: 2,
                                },
                                640: {
                                    slidesPerView: 2,
                                },

                                768: {
                                    slidesPerView: 2.2,
                                },
                                992: {
                                    slidesPerView: 3.2,
                                },
                                1024: {
                                    slidesPerView: 3.2,
                                },
                                1440: {
                                    slidesPerView: 4.2,
                                },


                            }}
                        >
                            {data?.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className='main-img-class mb-5'>
                                        {IMG_URL + item.image && (
                                            <img src={IMG_URL + item.image} className="diffrent-img" alt="..." />
                                        )}
                                        <Card className='Diffrent-Card'>
                                            <Card.Body>
                                                <Card.Title>{item?.title}</Card.Title>
                                                <div className="card-wrapper">
                                                    <div className="reveal-details differencecardText">
                                                        <p> {parse(item?.description) || ""}</p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DiscoverMa