import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";

import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';


// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import "./Benifits.css";

const Benifits = () => {
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }

    };

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/dy-patil/student-benefits`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);

    return (
        <>
            <section className='DY-benifits-section'>
                <Container>
                    <div className='heading'>
                        <h4 className='title'>Students Benefits</h4>
                        <div className='text-border' />
                    </div>
                    <div className='benifit-slider'>


                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            // spaceBetween={30}
                            centeredSlides={true}
                            initialSlide={1}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: false,
                            }}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination]}
                            slideShadows={false}
                            className="mySwiper"
                            breakpoints={{

                                0: {
                                    slidesPerView: 1,
                                },

                                769: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 1,
                                    // spaceBetween:100,
                                },
                            }}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                            {data?.map((val, index) =>
                                <SwiperSlide>
                                    <div className='swiper-video'>
                                        <video className='benifit-videos' playsInline autoPlay mute controls >
                                            <source src={IMG_URL + val?.video}></source>
                                        </video>
                                        <div className='video-border' />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("prev")}
                        >
                            <div className="arrow-back" />
                        </div>
                        <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("next")}
                        >
                            <div className="arrow-next" />
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Benifits