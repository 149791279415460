import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./qualificationbcom.css";
import parse from "html-react-parser";

const QualificationBcom = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-com/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  return (
    <>
      <section className="qualification-bcom">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 desktopview-small-banner">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/Courses/bcom/small-banner.png"
                }
                className="bcom-smallbanner-img"
                alt="..."
              />
            </div>

            <div className="col-md-12 mobileview-small-banner">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/Mobile-Banner/Banner/B.Com.png"
                }
                className="bcom-smallbanner-img"
                alt="..."
              />
            </div>
            <div className="col-md-12">
              <div className="bcom-reason-title text-center">
                <h2 className="title">Who Qualified For Online B.Com.?</h2>
                <p>
                  Embark on your online B.Com journey with confidence, tailored
                  for working professionals.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {data?.map((item, index) => (
              <div className="col-xxl-4 col-xl-4 col-md-6 col-12  ">
                <div className="main-qualification-card" key={index}>
                  <div className="row">
                    <div className="col-md-2 col-3">
                      <div className="image-back">
                        {item.image && (
                          <img
                            src={IMG_URL + item?.image}
                            className="bcom-qualified-img"
                            alt="..."
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-10 col-9">
                      <h2 className="reason-heading">{item?.name}</h2>
                      <p className="feature-text">
                        {parse(item?.description || "")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default QualificationBcom;
