import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./OnlineBa.css";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
const OnlineBa = () => {
  const [showone, setShowone] = useState(false);
  const handleCloseone = () => setShowone(false);
  const handleShowone = () => setShowone(true);

       // become partner modal
       const [show, setShow] = useState(false);
       const handleClose = () => setShow(false);
       const handleShow = () => setShow(true);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/ba/what`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="online-ba">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              {data?.map((d) => (
                <div className="heading-holder">
                  <h4 className="title">What is Online B.A ?</h4>
                  <p>{parse(d?.description) || ""}</p>
                  {/* <p>Unlock your potential with our Online B.A. program - a flexible and convenient solution for distance learners, entrepreneurs, and working professionals.
                    Immerse yourself in quality education, tailored for your schedule. Our interactive platform fosters collaborative learning, empowering you to balance academics and careers.
                    Elevate your future with a
                    diverse curriculum and expert faculty. Join us on the journey of academic excellence, where accessibility meets opportunity.</p> */}
                </div>
              ))}
            </div>
            <div className="col-lg-6 ">
              <div className="bg-orange">
                <div className="image-holder">
                  <img
                    className="rounded-img "
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Courses/ba/serious-student.png"
                    }
                  />
                  <div className="circle-video " onClick={handleShowone}>
                    <img
                      className="video-img "
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Courses/ba/play-button.png"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='modal-btn-div'>
            <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Become A Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BecomePartner />
        </Modal.Body>
      </Modal>
      {/* video modal */}
      <Modal
        className="modal-video"
        show={showone}
        onHide={handleCloseone}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {data?.map((d) => (
            <div className="round-video">
              <video
                className="online-video"
                playsInline
                autoPlay
                mute
                controls
              >
                <source src={IMG_URL + d?.video}></source>
              </video>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OnlineBa;
