import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./Reason.css";
import parse from "html-react-parser";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Reason = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bca/resons`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="Bca-reason-section">
        <div className="container">
          <div className="main-title">
            <h4 className="title">Top Reasons to Choose Online BCA</h4>
          </div>

          <div className="row">


            {/* {data?.map((image, index) => (
              <SwiperSlide>
                <div className=" col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ">

                  <div className="main-card" style={{ maxWidth: '200px', height: 'auto' }}>

                    <div className="img-div" >
                      <img className="reason-img" src={IMG_URL + image?.image} />
                      <div className="overlay-color"></div>
                    </div>
                    <div className="overlay-text">
                      <p>{parse(image?.description) || ""}</p>
                    </div>

                  </div>



                </div>
              </SwiperSlide>
            ))} */}

            <div className="">
              <div  >
                <Swiper
                  spaceBetween={20} // Adjust the space between slides as needed
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"

                  breakpoints={{
                    0: { slidesPerView: 1 },
                    360: { slidesPerView: 1 },
                    576: { slidesPerView: 2, },
                    640: { slidesPerView: 2 },
                    768: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                    1800: { slidesPerView: 4 },
                    2500: { slidesPerView: 4 },
                  }}

                >
                  {data?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="main-card">
                        <div className="img-div" >
                          <img className="reason-img" src={IMG_URL + image?.image} style={{ maxWidth: '310px', height: 'auto' }} />
                          <div className="overlay-color"></div>
                        </div>
                        <div className="overlay-text" style={{ maxWidth: '310px', height: 'auto' }}>
                          <p>{parse(image?.description) || ""}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>


          </div>
        </div>
      </section >
    </>
  );
};

export default Reason;
