import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from '../../../../../Utils/MyContext/MyContext';
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import './SmallBannerMba.css'
const SmallBannerMba = () => {
    const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mba/what`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
    return (
        <>
            <section className='bba-small-ban-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Courses/small-banners/mba-banner-small.png'} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/MBA.png'} />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default SmallBannerMba