import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import "./Questions.css";
import parse from "html-react-parser";

const Questions = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/all-faq`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="question-section ">
        <Container>
          <div className="heading">
            <h4 className="title">Frequently Asked Questions</h4>
            <div className="text-border" />
          </div>

          <div className="questions">
            <Accordion defaultActiveKey="0">
              {data?.map((val, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{val?.name}</Accordion.Header>
                  <Accordion.Body>
                    <p> {parse(val?.description)}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
              {/* <Accordion.Item eventKey="1" className='mt-4'>
                                <Accordion.Header >What are the eligibility Criteria for the Online MBA program?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className='mt-4'>
                                <Accordion.Header >Is the Online degree globally accepted?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className='mt-4'>
                                <Accordion.Header >What is the admission process?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className='mt-4'>
                                <Accordion.Header >What is the evaluation and assessment pattern?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className='mt-4'>
                                <Accordion.Header >What are payment modes for fees?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="6" className='mt-4'>
                                <Accordion.Header className='title'>Is the Online MBA degree recognized by statutory bodies?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7" className='mt-4'>
                                <Accordion.Header className='title'>Why should one choose Dr. D. Y. Patil Vidyapeeth, Pune?</Accordion.Header>
                                <Accordion.Body>
                                    <p>  Profcyma is a technology partner with Premium Institutes like DY Patil Pune We take admissions after the procedure of profile screening and provide an online platform for institutes to conduct classes. The course curriculum, class schedules,
                                        and certification are provided by the respective institute and Profcyma has no role to play in the same.</p>
                                </Accordion.Body>
                            </Accordion.Item> */}
            </Accordion>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Questions;
