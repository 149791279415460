import { React, useRef, useEffect, useState, useContext } from "react";
import './Mcacard.css'
import SmallBanMca from '../SmallBanMca/SmallBanMca'
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";

function Mcacard() {

    const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mca/advantages`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
    return (
        <>
            <section className='mca-card-section'>
                <div className='container'>
                    
                    <div className='row'>
                    {data?.map((val)=>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                        <div className='main-div'>
                            <div className='card-div'>
                                <div className='card-inner-div'>
                                    <p>{val?.name}:{parse(val?.description) || ""}</p>
                                </div>
                            </div>
                            <div className='shape-div'>
                                <div className='orange-shape' />
                            </div>
                            <div className='shadow-div' />
                        </div>
                    </div>
                   
                    )}
                </div>
                   
                    
                </div>
                <SmallBanMca/>
            </section>
        </>
    )
}

export default Mcacard