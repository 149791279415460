import React from 'react'
import Banner from './Banner/Banner'
import OnlineMba from './OnlineMba/OnlineMba'
import ChooseMba from './ChooseMba/ChooseMba'
import SliderMba from './SliderMba/SliderMba'
import DifferenceMba from './DifferenceMba/DifferenceMba'
import QualifiedMba from './QualifiedMba/QualifiedMba'
import SmallBannerMba from './small-banner-mba/SmallBannerMba'
const MBA = () => {
  return (
    <>
      <section className='Mba'>
        <Banner />
        <OnlineMba />
        <ChooseMba />
        <SliderMba />
        <DifferenceMba />
        <SmallBannerMba />
        <QualifiedMba />
      </section>

    </>
  )
}

export default MBA