import { React, useRef, useEffect, useState, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import NavDropdown from "react-bootstrap/NavDropdown";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./Header.css";
import { Context } from "../../../utils/context";
import CommonCourses from "./CommonCourses/CommonCourses";
// import Main_junction from "../../main-junction/Main_junction";
import Cookies from "js-cookie";
import InquiryFormModal from "../InquiryFormModal/InquiryFormModal";
// import CommonCourses from "./CommonCourses/CommonCourses";
library.add(fas);

function Header() {
  const { IMG_URL, getData } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const inquirySubmitted = Cookies.get('inquirySubmitted');
    if (!inquirySubmitted) {
      setModalShow(true);
    }
  }, []);


  const email = "inquiry@profcyma.com";

  const [activeDropdown, setActiveDropdown] = useState(null);
  // courses dropdown close code start
  const [toggleHide, setToggleHide] = useState(false);

  const handleToggle = () => {
    setToggleHide(!toggleHide);
  };

  const handleLinkClick = () => {
    setToggleHide(false);
  };

  // courses dropdown close code end

  const isActive = (path) => {
    return location.pathname === path;
  };

  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  console.log(data, "datacon");
  const [courseTypes, setCourseTypes] = useState([]);
  const [courses, setCourses] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/common/all-university`
      );
      const response2 = await getData(
        `/without-login/ed-tech/courses/common/common-type/courses-type`
      );
      const response3 = await getData(
        `/without-login/ed-tech/courses/common/common-type/courses`
      );

      // http://127.0.0.1:8520/api/without-login/ed-tech/university/common/all-university?term=ami
      setData(response?.data?.data);
      console.log(response?.data, "response1");
      setCourseTypes(response2?.data);
      console.log(response2?.data, "coursesdatamain");
      setCourses(response3?.data);
      console.log(response3?.data, "coursesdata");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "fgfgfg");

  useEffect(() => {
    getDataAll();
  }, []);

  const [activeLink, setActiveLink] = useState("home");

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };



  return (
    <>
      <section className="Header-section">
        {/* <Main_junction /> */}
        <div className="all-header-content">
          <div className="upper-head">
            <Container>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                  <div className="d-flex ">
                    <FontAwesomeIcon
                      icon="fa-solid fa-envelope"
                      className="mail-icon"
                    />
                    <div>
                      <a
                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`}
                        target="_blank"
                        className="mail-text"
                        rel="noopener noreferrer"
                      >
                        {email}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 text-end">
                  <div className="icons-div">
                    <a
                      href="https://www.facebook.com/ProfcymaCareerSolutions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="icons"
                        src={process.env.PUBLIC_URL + "/assets/images/icons/fb.png"}
                        alt="Facebook Logo"
                      />
                    </a>
                    <a
                      href="https://twitter.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="icons"
                        src={process.env.PUBLIC_URL + "/assets/images/icons/twitter.png"}
                        alt="Twitter Logo"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/profcyma_careersolutions/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="icons"
                        src={process.env.PUBLIC_URL + "/assets/images/icons/insta.png"}
                        alt="Instagram Logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="main-header">
            <Container>
              <Navbar collapseOnSelect expand="lg">

                <Navbar.Brand >
                  <Link to='/'>
                    <img
                      className="headlogo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/logo/Profcyma-logotwo.png"
                      }
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto nav-bar">
                    {/* desktop view dropdown start */}
                    <div className="d-flex   desktop-view-dropdown ">
                      <div className="courses-dropdown-main">
                        <>
                          <NavDropdown
                            autoClose={"outside"}
                            // id="nav-dropdown"
                            // className="main-dropdown"
                            show={toggleHide}
                            onToggle={handleToggle}
                            title={
                              <span>
                                Courses <FontAwesomeIcon icon={faChevronDown} />
                                <div className="border_effect"></div>
                              </span>
                            }
                          >
                            {toggleHide && (
                              <>
                                <NavDropdown.Item eventKey="4.1">
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={courseTypes[0]?.id}
                                  >
                                    <Row>
                                      <Col sm={3}>
                                        <Nav
                                          variant="pills"
                                          className="flex-column"
                                        >
                                          {courseTypes?.map((val) => (
                                            <Nav.Item>
                                              <Nav.Link eventKey={val?.id}>
                                                {val?.name}
                                              </Nav.Link>
                                            </Nav.Item>
                                          ))}
                                        </Nav>
                                      </Col>
                                      <Col sm={9}>
                                        <Tab.Content className="main-tab-content">
                                          {courseTypes?.map((val) => (
                                            <Tab.Pane eventKey={val?.id}>
                                              <CommonCourses
                                                handleLinkClick={
                                                  handleLinkClick
                                                }
                                                type_id={val?.id}
                                              />
                                            </Tab.Pane>
                                          ))}
                                          <Tab.Pane eventKey="commoncourse">
                                            <CommonCourses
                                              handleLinkClick={handleLinkClick}
                                            />
                                          </Tab.Pane>
                                        </Tab.Content>
                                      </Col>
                                    </Row>
                                  </Tab.Container>
                                </NavDropdown.Item>
                              </>
                            )}
                          </NavDropdown>
                        </>
                      </div>
                      <NavDropdown
                        id="nav-dropdown"
                        onClick={() => setActiveDropdown("universities")}
                        title={
                          <span>
                            Universities
                            <FontAwesomeIcon icon={faChevronDown} />
                            <div className="border_effect"></div>
                          </span>
                        }
                      >
                        <div className="row">
                          {data?.map((val) => (
                            <div className="col-md-6">
                              <NavDropdown.Item eventKey="4.4">
                                <div className=""></div>
                                <Link
                                  className="drop-nav-link"
                                  // to={`/ed-tech/university/common-university/${val?.id}`}
                                  to={`/ed-tech/university/${val.id}/${val.name.replace(/\s+/g, '-').toLowerCase()}`}
                                >    <img className="logo-uni me-2" src={IMG_URL + val?.image} alt="Logo" />
                                  {/* {val?.name} */}
                                </Link>
                              </NavDropdown.Item>
                            </div>
                          ))}
                        </div>
                      </NavDropdown>
                    </div>
                    {/* desktop view dropdown end */}
                    {/* mobile view dropdown start */}

                    <div className="mobile-view-dropdown">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Courses</Accordion.Header>
                          <Accordion.Body>
                            <Accordion
                              defaultActiveKey="1"
                              className="inner-accordian"
                            >
                              {courseTypes?.map((val) =>
                                <Accordion.Item eventKey={val?.id}>
                                  <Accordion.Header>{val?.name}</Accordion.Header>
                                  <Accordion.Body>
                                    {val?.ed_tech_courses?.map((val1) =>
                                      <NavDropdown.Item eventKey={val?.id}>
                                        <Link
                                          className="drop-nav-link"
                                          // to="/pg-courses/mba"
                                          // to={`/ed-tech/courses/${val?.id}`}
                                          to={`/ed-tech/courses/${val?.id}/${val.name.replace(/\s+/g, '-').toLowerCase()}`}

                                        >
                                          {val1?.name}
                                        </Link>
                                      </NavDropdown.Item>
                                    )}

                                  </Accordion.Body>
                                </Accordion.Item>
                              )}

                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Universities</Accordion.Header>
                          <Accordion.Body>
                            <NavDropdown.Item eventKey="4.1">
                              <Link
                                className="drop-nav-link"
                                to="/ed-tech/university/dy-patil"
                              >

                                Dr. D.Y. Patil University
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.2">
                              <Link
                                className="drop-nav-link"
                                to="/ed-tech/university/amity-university"
                              >
                                Amity University
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item eventKey="4.4">
                              <Link
                                className="drop-nav-link"
                                to="/ed-tech/university/bharati-vidyapeeth"
                              >
                                Bharati Vidyapeeth
                              </Link>
                            </NavDropdown.Item>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    {/* mobile view dropdown end */}

                    <NavLink
                      to="/ed-tech/admission"
                      className="nav-link"
                      // active={activeDropdown === '/ed-tech/admission'}
                      active={
                        location?.pathname === "/ed-tech/admission" || false
                      }
                      onClick={handleNavItemClick}
                    >
                      Admission
                      <div className="border_effect"></div>
                    </NavLink>

                    <NavLink
                      to="/ed-tech/blog"
                      className="nav-link"
                      active={location?.pathname === "/ed-tech/blog" || false}
                      // active={activeDropdown === '/ed-tech/blog'}
                      onClick={handleNavItemClick}
                    >
                      Blogs
                      <div className="border_effect"></div>
                    </NavLink>

                    <NavLink className="nav-link " to="/ed-tech/becomepartner" onClick={handleNavItemClick}>
                      Become a Partner
                      <div className="border_effect"></div>
                    </NavLink>

                    <div className="btn-div mb-lg-0 mb-md-2 mb-sm-2 mb-2">
                      <Button className="contact-btn" onClick={() => {
                        setModalShow(true);
                        handleNavItemClick();
                      }}>Apply Now</Button>
                    </div>

                    <Link to="/ed-tech/contact-us">
                      <div className="btn-div">
                        <Button className="contact-btn" onClick={handleNavItemClick}>Contact Us</Button>
                      </div>
                    </Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
        </div>
      </section>

      <InquiryFormModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        modalShow={modalShow}

      />

    </>
  );
}

export default Header;
