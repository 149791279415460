import React from 'react'

const DiplomaSmallBanner = () => {
    return (
        <>
            <section className='bba-small-ban-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Courses/small-banners/diplomasmall.png'} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/Diploma.png'} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DiplomaSmallBanner