import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './WhoQualifiedMsc.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Keyboard, Mousewheel } from 'swiper';
const WhoQualifiedMsc = () => {
    const qualifiedData = [
        {
            title: 'Academic Qualifications',
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/MA/academic.png",
            description: "Elevate your career with a bachelor's degree from an accredited institution. While a business-related background is beneficial, diverse fields are welcomed",
        },
        {
            title: 'Professional Experience',
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Professional.png",
            description: "Our program values seasoned professionals. Demonstrate your expertise with a minimum of several years of work experience, providing a rich foundation for collaborative learning.",
        },
        {
            title: 'Strong Time Management Skills',
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Technological.png",
            description: "Master the art of balance. Our online M.A. demands effective time management, allowing you to integrate studies into your busy professional life seamlessly.",
        },
        {
            title: 'Effective Communication Skills',
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/MA/time-management.png",
            description: "Hone your ability to articulate ideas. Engage in vibrant virtual discussions, written assignments, and collaborative projects to enhance your communication prowess.",
        },
        {
            title: 'Technological Proficiency',
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/MA/negotiation.png",
            description: "Navigate the digital landscape effortlessly. Embrace technology with confidence, ensuring a smooth online learning experience tailored for working professionals.",
        },

    ];
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }

    };

    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/msc/who-qualified`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);

    return (
        <>
            <section className='who-qualified-msc'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder text-center '>
                            <h4 className='title'>Who Qualified For Online M.Sc.?</h4>
                            <p>Are you a working professional aspiring to earn your M.Sc. online? Unlocking new opportunities is within reach with the right qualifications.
                                Ensure</p>
                            <p>Earn Your M.Sc Online with Academic Excellence</p>
                        </div>
                        <Swiper

                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 120,
                                modifier: 1,
                                slideShadows: false,
                            }}

                            modules={[EffectCoverflow, Autoplay]}

                            className="mySwiper"
                            onSwiper={(swiper) => setSwiperInstance(swiper)}

                        >
                            {data?.map((qualification, index) => (
                                <SwiperSlide>
                                    <div className='card mb-5'>
                                        <div className='orange-circle mx-auto mt-5'>
                                            <img className='icon-img' src={IMG_URL+qualification.image} alt={`icon-${index}`} />
                                        </div>
                                        <div className='text-heading text-center mt-4'>
                                            <h4>{qualification.title}</h4>
                                            <p className='mt-5 px-3'>{parse(qualification?.description) || ""}</p>
                                        </div>

                                    </div>

                                </SwiperSlide>
                            ))}

                        </Swiper>

                        <div
                            className="main-slider "
                            onClick={() => handleMainSliderClick("prev")}
                        >
                            <div className='main-arrow-back'>
                                <div className="arrow-back arrowmobile" />
                            </div>
                        </div>
                        <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("next")}
                        >
                            <div className='main-arrow-next'>
                                <div className="arrow-next arrowmobile" />
                            </div>
                        </div>
                        <div className='content-holder text-center'>
                            <p>Explore the future of education and career growth –<span>Enroll now!</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhoQualifiedMsc