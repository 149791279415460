import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./secondsection.css";
import { Row, Col, Container } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
import { Modal } from "react-bootstrap";

const SecondSection = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

       // become partner modal
       const [show, setShow] = useState(false);
       const handleClose = () => setShow(false);
       const handleShow = () => setShow(true);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/what`
    );
    const response2 = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/advantages`
    );
    await setData(response?.data);
    await setData2(response2?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="onlinebsc-section">
        <div className="onlinebsc-main">
          <div className="online-part1">
            <Container>
              <div className="part1-main">
                {data?.map((d) => (
                  <Row>
                    <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
                      <div className="video-column">
                        <LazyLoad>
                          <video
                            className="bscvideo"
                            playsInline
                            autoPlay
                            mute
                            controls
                          >
                            <source src={IMG_URL + d?.video}></source>
                          </video>
                        </LazyLoad>
                      </div>
                    </Col>
                    <Col
                      xxl={7}
                      xl={7}
                      lg={7}
                      md={12}
                      sm={12}
                      xs={12}
                      className="my-auto"
                    >
                      <div className="onlinebsc-contents ">
                        <h2 className="title">{d?.name}</h2>
                        <p>{parse(d?.description) || ""}</p>
                        {/* <p>Explore boundless possibilities with our Online B.Sc program. Tailored for distance learners,
                                                    entrepreneurs, and working professionals, our SEO-friendly curriculum empowers you to excel in
                                                    a flexible virtual environment. Dive into interactive modules, connect with industry experts,
                                                    and advance your career seamlessly. Elevate your education, embrace convenience, and unlock a
                                                    world of opportunities with our Online B.Sc program. Enroll today
                                                    to chart your path to success from anywhere in the world.</p> */}
                      </div>
                    </Col>
                  </Row>
                ))}

                <div className='modal-btn-div text-end'>
                  <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
                </div>
              </div>
            </Container>
            <div className="bgcolr"></div>
          </div>
        </div>
        <div className="onlinebsc-main2">
          <div className="online-part2">
            <Container>
              <div className="part2-main">
                <Row>
                  <Col
                    xxl={5}
                    xl={5}
                    lg={5}
                    md={12}
                    sm={12}
                    xs={12}
                    className="my-auto"
                  >
                    <div className="contents-main">
                      {data2?.map((d) => (
                        <div className="cont d-flex">
                          <img
                            className="check-circle-image me-3 mt-2"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/Courses/bsc/Icon material-check-circle.png"
                            }
                          />
                          <p className="cont-text">
                            {parse(d?.description) || ""}
                          </p>
                        </div>
                      ))}
                      {/* <div className='cont d-flex'>
                                                <img className="check-circle-image me-3 mt-2" src={process.env.PUBLIC_URL + '/assets/images/Courses/bsc/Icon material-check-circle.png'} />
                                                <p className='cont-text'>Dive into modules, connect with experts, and seamlessly advance your career. Elevate education, embrace convenience, and unlock opportunities</p>
                                            </div>
                                            <div className='cont d-flex'>
                                                <img className="check-circle-image me-3 mt-2" src={process.env.PUBLIC_URL + '/assets/images/Courses/bsc/Icon material-check-circle.png'} />
                                                <p className='cont-text'>Our program empowers you to explore boundless possibilities and excel in your chosen field.</p>
                                            </div>
                                            <div className='cont d-flex'>
                                                <img className="check-circle-image me-3 mt-2" src={process.env.PUBLIC_URL + '/assets/images/Courses/bsc/Icon material-check-circle.png'} />
                                                <p className='cont-text'>Online B.Sc opens doors for professional growth. Join us to elevate your skills and shape a successful future.</p>
                                            </div> */}
                    </div>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
                    <div className="">
                      <img
                        className="images"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Courses/bsc/onlinegirl.png"
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="brown-bg"></div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Become A Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BecomePartner />
            </Modal.Body>
          </Modal>
      </section>
    </>
  );
};

export default SecondSection;
