import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./discoverdiploma.css";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import parse from "html-react-parser";

const discoverData = [
  {
    title: "Flexibility Redefined",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img1.png",
    description:
      "Learn at your own pace, anytime, anywhere, and tailor your study schedule to fit your life commitments.",
  },
  {
    title: "Work-Integrated Learning",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img2.png",
    description:
      "Gain practical skills and real-world insights through projects and assignments that directly apply to your current or future career.",
  },
  {
    title: "Cost-Effective Solutions",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img3.png",
    description:
      "Save on commuting, accommodation, and traditional course expenses, with online diplomas offering an affordable alternative to conventional education.",
  },
  {
    title: "Innovative Technology",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img4.png",
    description:
      "Embrace cutting-edge tools and platforms for interactive and engaging learning experiences, staying ahead with the latest industry trends.",
  },
  {
    title: "Global Networking Opportunities",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img5.png",
    description:
      "Connect with diverse professionals and experts worldwide, expanding your network and opening doors to international collaboration and career prospects.",
  },
  {
    title: "Customized Learning Experience",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img6.png",
    description:
      "Tailor your education to your unique needs, focusing on specific areas of interest and adapting the curriculum to match your learning style.",
  },
  {
    title: "Customized Learning Experience",
    imgSrc:
      process.env.PUBLIC_URL +
      "/assets/images/Courses/diploma/discover-diploma/img6.png",
    description:
      "Tailor your education to your unique needs, focusing on specific areas of interest and adapting the curriculum to match your learning style.",
  },
];

const DiscoverDiploma = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/discover`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="discover-diploma">
        <Container>
          <div className="title-discover-diploma">
            <h2>Discover the Power of Online Diploma</h2>
          </div>
          <div className="discover-swiper">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className=""
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 30 },
                360: { slidesPerView: 1, spaceBetween: 30 },
                576: { slidesPerView: 1, spaceBetween: 30 },
                640: { slidesPerView: 2, spaceBetween: 30 },
                768: { slidesPerView: 2, spaceBetween: 30 },
                1024: { slidesPerView: 3, spaceBetween: 30 },
                1400: { slidesPerView: 4, spaceBetween: 30 },
                1800: { slidesPerView: 4, spaceBetween: 30 },
                2500: { slidesPerView: 4, spaceBetween: 30 },
              }}
            >
              {data?.map((step, index) => (
                <SwiperSlide key={index}>
                  <Card className="main-card mb-5">
                    <Card.Body>
                      <Card.Title>
                        <h2 className="step-title">{step.name}</h2>
                      </Card.Title>
                      <div className="discover-image-main">
                        <img
                          src={IMG_URL + step.image}
                          className="card-img-class"
                          alt="..."
                        />
                      </div>
                      <Card.Text>
                        <p className="card-text">{parse(step.description)}</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DiscoverDiploma;
