import React from 'react'
import "./OnlineCourse.css"
const OnlineCourse = () => {
  return (
    <>
      <section className='amityOnlineCourse'>
        <div className='container'>
          <div className='amityOCheadtext'>
            <h3>We deliver an online MBA course that <br/> creates future-ready people.</h3>
            <p>For a rewarding management career, an MBA from Amity University is an ideal choice. The online program guarantees a career boost, providing flexibility for continuous skill enhancement. Study at your own pace, ensuring top-notch education without compromising quality. Secure your professional success with Amity University’s MBA program.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default OnlineCourse