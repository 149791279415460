import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "./AchievementAmity.css";
function AchievementAmity() {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/achievements`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  const AchievementSlider = [
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/certificate1.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/Certificate2.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/certificate3.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/certificate1.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/Certificate2.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/AmeityUniversity/certificate3.png",
    },
  ];
  return (
    <>
      <section className="amaity-achievement-section">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center mb-4">
              <h5 className="title">Achievement</h5>
            </div>

            <Swiper
              modules={[Pagination]}
              spaceBetween={30}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                360: {
                  slidesPerView: 1,
                },
                467: {
                  slidesPerView: 1,
                },
                567: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 2,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                // 1440: {
                //     slidesPerView: 4,
                // },
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="main-img-class mb-5">
                    {item?.image && (
                      <img
                        src={IMG_URL + item?.image}
                        className="achive-img"
                        alt="..."
                      />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default AchievementAmity;
