import React from 'react'
import BlibBanner from './BlibBanner/BlibBanner';
import OnlineBlib from './online-blib/OnlineBlib';
import TopReasonBlib from './topreason-blib/TopReasonBlib';
import QualifiedBlib from './qualified-blib/QualifiedBlib';


const BLib = () => {
  return (
    <>
      <BlibBanner />
      <OnlineBlib/>
      {/* <TopReasonBlib/>   */}
      <QualifiedBlib/>     
    </>
  )
}

export default BLib;