import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import "./ChooseMba.css"
import ImageContext from '../../../../../Utils/MyContext/MyContext';
const ChooseMba = () => {

  const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mba/resons`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className='OnlineMBA'>
        <div className='container'>
          <div>
            <h3 className='Onlineheading'>Top Reasons to Choose Online MBA</h3>
          </div>
          {data?.map((val)=>
          <div className="row ">
            <div className="col-lg-6 col-md-6">
              <div className="online_content">
                <h3 className='Onlinesubheading'>{val?.title}</h3>
                <p><b>{parse(val?.description) || ""}</b> </p>
                {/* <p>In the fast-paced business world, we understand the unique challenges working professionals face. Our Online MBA program is thoughtfully designed to cater specifically to your needs, ensuring a seamless integration of education with your professional journey.</p> */}
              </div>
            </div>
            <div className="col-lg-5 col-md-6 mx-auto">
              <div className="text-end">
                <video className='online-video' playsInline autoPlay mute controls >
                  <source src={IMG_URL+val.video}></source>
                </video>
              </div>
            </div>
          </div>
          )}
        </div>
      </section>
    </>
  )
}

export default ChooseMba