import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./WhoQualifiedBa.css";
import parse from "html-react-parser";
const WhoQualifiedBa = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/ba/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="who-qualified-ba">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="heading-holder">
                <h4 className="title">Who Qualified For Online B.A.?</h4>
                <h5>
                  Qualifications for Pursuing an Online B.A. for Working
                  Professionals
                </h5>
                <span>
                  In today's dynamic work environment, earning a Bachelor of
                  Arts (B.A.) online can boost your career without compromising
                  your professional commitments. Here are the key qualifications
                  for working professionals considering an online B.A.
                </span>
                <p>
                  Embark on your academic journey while thriving in your
                  professional life with these essential qualifications for
                  online B.A. success.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              {data?.map((item) => (
                <div className="d-flex mb-2" key={item.name}>
                  <div className="orange-circle">
                    <img className="icon-img" src={IMG_URL + item?.image} />
                  </div>
                  <div className="text-holder">
                    <h5>{item?.name}</h5>
                    <p>{parse(item?.description || "")}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoQualifiedBa;
