import React from 'react'
import Banner from './banner/Banner'
import SecondSection from './second-section/SecondSection'
import Topreason from './top-reason/Topreason'
import QualifiedBsc from './QualifiedBsc/QualifiedBsc'
import DiscoverBsc from './discoverbsc/DiscoverBsc'


const Bsc = () => {
  return (
    <>
        <Banner />
        <SecondSection/>
        <Topreason />
        <DiscoverBsc/>
        <QualifiedBsc/>
        
    </>
  )
}

export default Bsc