import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import './BhartiEducation.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const BhartiEducation = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/bharati-vidyapeeth/banner-two`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className='Bharti-educational'>
        <div className='container'>
          <div className='internationalheadtxt'>
            <h3>Bharati Vidyapeeth</h3>
            <div class="border-line mb-3"></div>
            <p>Centre for Distance and Online Education, Start your Amazing Career</p>
          </div>

          {/* <div className='mb-lg-4 mb-md-0 mb-0'>
                        <img src={process.env.PUBLIC_URL + '/assets/images/university/bharati-vidyapeeth/banner/ban2.png'} className='internationalbanner' />
                    </div> */}

          <div className="bann-div desktop-view-banner">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="slider-section mobile-view-banner">
            <div className="banner-slide-div">
              {/* <div className='image-div'>
                    {name.map((image) => (
                      <img key={image.id} src={image.image41} className='banner-img1' />
                    ))}
                  </div> */}
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="image-div">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="banner-img1"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default BhartiEducation