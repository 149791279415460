import React from 'react'
import MscBanner from './mscbanner/MscBanner'
import OnlineMsc from './online-msc/OnlineMsc'
import TopReasonmsc from './TopReason-msc/TopReasonmsc'
import MscSmallBanner from './Small-banner/MscSmallBanner'
import WhoQualifiedMsc from './Who-qualified-msc/WhoQualifiedMsc'

const Msc = () => {
    return (
        <>
            <MscBanner />
            <OnlineMsc />
            <TopReasonmsc />
            <MscSmallBanner />
            <WhoQualifiedMsc />
        </>
    )
}

export default Msc