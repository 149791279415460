import React from 'react'
import "./small.banner.css"

const Small_banner = () => {
    return (
        <>
            <section className='bba-small-ban-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Courses/small-banners/bba.png'} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/bba.png'} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Small_banner