import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./QualifiedBsc.css";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

function QualifiedBsc() {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="qualified-section">
        <div className="qualified-main">
          <Container>
            <div className="">
              <Row>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                  className=" my-auto"
                >
                  <div className="qualified-content">
                    <h3 className="title">Who Qualified For Online B.Sc.?</h3>
                    <p className="">
                      Are you a working professional aspiring to earn your B.Sc.
                      online? Unlocking new opportunities is within reach with
                      the right qualifications. Ensure your success with the
                      following key attributes:
                    </p>
                  </div>
                </Col>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={12}
                  sm={12}
                  xs={12}
                  className="chart-content my-auto"
                >
                  {data?.map((item, index) => (
                    <div className="abc">
                      <div className="row">
                        <div className="col-2">
                          <div className=" details-titles last">
                            <img
                              className="images"
                              src={IMG_URL + item?.image}
                            />
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="details-content">
                            <h6>{item?.name}</h6>
                            <p>{parse(item?.description)}</p>
                            {/* <p>A bachelor's degree from an accredited institution sets the foundation for your online B.Sc. journey.</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className='abc'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <div className=' details-titles'>
                                                    <img className='images' src='/assets/images/Courses/bsc/profesion.png' />
                                                </div>
                                            </div>
                                            <div className='col-10'>
                                                <div className='details-content'>
                                                    <h6>Professional Experience</h6>
                                                    <p>Leverage your work experience to enrich your studies and bring real-world insights to the virtual classroom.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='abc'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <div className=' details-titles'>
                                                    <img className='images' src='/assets/images/Courses/bsc/time.png' />
                                                </div>
                                            </div>
                                            <div className='col-10'>
                                                <div className='details-content'>
                                                    <h6>Strong Time Management Skills</h6>
                                                    <p>Balance work, life, and academics seamlessly with effective time management, a crucial skill for online B.Sc. success.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='abc'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <div className=' details-titles'>
                                                    <img className='images' src='/assets/images/Courses/bsc/negotiation.png' />
                                                </div>
                                            </div>
                                            <div className='col-10'>
                                                <div className='details-content'>
                                                    <h6>Effective Communication Skills</h6>
                                                    <p>Engage in virtual discussions and collaborative projects confidently, showcasing your communication prowess.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='abc'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <div className=' details-titles '>
                                                    <img className='images' src='/assets/images/Courses/bsc/technology.png' />
                                                </div>
                                            </div>
                                            <div className='col-10'>
                                                <div className='details-content'>
                                                    <h6>Technological Proficiency</h6>
                                                    <p>Navigate online platforms effortlessly, demonstrating your tech-savvy approach to enhance the online learning experience.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default QualifiedBsc;
