import { React, useRef, useEffect, useState, useContext } from "react";
import "./Mcabanner.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
function Mcabanner() {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/mca/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="mca-banner mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 desktop-view-banner">
              {/* <div className='banner-img-main'>
                                {data.map((val) => (
                                    <img key={val.id} src={IMG_URL+val.image} className='banner-img' />
                                ))}

                            </div> */}
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="image-div">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="bca-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="col-md-12 mobile-view-banner">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide>
                    <div className="image-div">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        className="bca-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mcabanner;
