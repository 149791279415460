import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import './readmoresec.css'
import ImageContext from '../../../../Utils/MyContext/MyContext';
import { NavLink } from 'react-router-dom';
import parse from "html-react-parser";
import { ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";
import { Container } from "react-bootstrap";

export const ReadMoreSec = ({ blogData }) => {
    const { name } = useContext(ImageContext);
    const { getData, IMG_URL } = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); 

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading &&
                <Container className="mt-5">
                    <ShimmerThumbnail height={400} rounded />
                    <div className="mt-5">
                        <ShimmerSectionHeader center />
                    </div>
                </Container>
            }
            {!loading && (
                <section className='inner-banner mt-4 '>
                    <div className='container'>
                        <div className='banners  '>
                            <div className='row'>
                                <div className='col-md-12 desktop-view-banner'>
                                    <div className='innerbanner-img-main mt-2'>
                                        <img
                                            key={blogData.id}
                                            src={IMG_URL + blogData.banner_image}
                                            className="innerbanner-img"
                                        />
                                        <div className='icon-div'>
                                            <NavLink to="/ed-tech/blog"><img className='backside-icon' src={process.env.PUBLIC_URL + "/assets/images/Blogs/Third-tab/back.png"} /></NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 mobile-view-banner'>
                                    <div className='innerbanner-img-main mt-2'>
                                        <img
                                            key={blogData.id}
                                            src={IMG_URL + blogData.banner_image}
                                            className="innerbanner-img"
                                        />
                                        <div className='icon-div'>
                                            <NavLink to="/ed-tech/blog"><img className='backside-icon' src={process.env.PUBLIC_URL + "/assets/images/Blogs/Third-tab/back.png"} /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='introduction'>
                            <h2 className='title'>Introduction</h2>
                            <p>{parse(blogData?.introduction) || ""}</p>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
