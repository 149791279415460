import React, { useState, useEffect } from "react";
import "./App.css";
import "./index.css";
import Loader from "./components/loader/Loader";
import { useLocation, Link, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";



// Start ED Tech imports
import Header from "./components/Ed-tech/header/Header";
import Footer from "./components/Ed-tech/footer/Footer";
// End ED Tech imports

import EdtechRoutes from "./components/Ed-tech/EdtechRoutes/EdtechRoutes";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";



function App() {
  // const [isFetching, setIsFetching] = useState(true);
  const { pathname } = useLocation();
  // const isEdTechRoute = pathname.includes("/ed-tech");
  // const isJobPortalRoute = pathname.includes("/job-portal");
  // const isFinanceRoute = pathname.includes("/finance");

  const [showHeaderFooter, setShowHeaderFooter] = useState(false);




  // useEffect(() => {
  //   setTimeout(function () {
  //     setIsFetching(false);
  //   }, 3000);

  //   setShowHeaderFooter(true);
  // }, []);
  // if (isFetching) {
  //   return (
  //     <Loader />
  //   );
  // }

  return (
    <>
      <TawkMessengerReact
        propertyId="6603be18a0c6737bd12550fa"
        widgetId="1hpv9t413"
      />
      <ScrollToTop />
      <Header />




      {/* <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/ed-tech/welcome" element={<Ed_tech_welcome />} />
        <Route path="/job-portal/welcome" element={<Job_portal_welcome />} />
        <Route path="/finance/welcome" element={<Finance_welcome />} />
        <Route path="/free-counselling/welcome" element={<Free_councelling_welcome />} />
        <Route path="/college-compare/welcome" element={<College_compare_welcome />} />
        <Route path="/comming-soon" element={<Comming_soon />} />

      </Routes>

      {isJobPortalRoute && pathname !== "/job-portal/welcome" && (
        <>
          <JPROutes />
          {showHeaderFooter && <Jpfooter />}
        </>
      )}
     
        
         
      
     
      {isFinanceRoute && pathname !== "/finance/welcome" && (
        <>
          <FinanceRoutes />
          {showHeaderFooter && <FFooter />}
        </>
      )} */}

      <EdtechRoutes />
      <Footer />
    </>
  );
}

export default App;