import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./AmityBanner.css";
import ImageContext from "../../../../Utils/MyContext/MyContext";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const AmityBanner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/banner`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="amity_bannerSection">
        <div className="container">
          <div className="banner-div desktop-view-banner">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* <div className="banner-div mobile-view-banner">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}

<div className="slider-section mobile-view-banner">
                <div className="banner-slide-div">
                  {/* <div className='image-div'>
                    {name.map((image) => (
                      <img key={image.id} src={image.image41} className='banner-img1' />
                    ))}
                  </div> */}
                  <Swiper
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {data?.map((image, index) => (
                      <SwiperSlide>
                        <div className="image-div">
                          <img
                            key={image.id}
                            src={IMG_URL + image?.image}
                            className="banner-img1"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
        </div>
      </section>
    </>
  );
};

export default AmityBanner;
