import React from 'react'
import './Mlibsmallban.css'

export default function Mlibsmallban() {
  return (
    <>
      <section className='Mlib-small-banner-section'>
        <div className='container'>
          <div className='row'>
            <div className='desktopview-small-banner'>
              <img className='Mlib-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/M.Lib/smallban.png"} />
            </div>

            <div className='mobileview-small-banner'>
              <img className='Mlib-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Banner/M.Lib.png"} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
