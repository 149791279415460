import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./banner.css";
import ImageContext from "../../../../Utils/MyContext/MyContext";

const Banner = () => {
  const { name } = useContext(ImageContext);
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="dy-ban-section">
        <div className="container">
          <div className="banner-div desktop-view-banner">
            {/* {name.map((image) => (
              <img key={image.id} src={image.image16} className='banner-img' />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="banner-img-main">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="banner-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="banner-div mobile-view-banner">
            {/* {name.map((image) => (
              <img key={image.id} src={image.image37} className='banner-img' />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="banner-img-main">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="banner-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="ban-sub-conttt">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="cont-div">
                  <p className="text">
                    Trust Profcyma to navigate the academic landscape and unlock
                    opportunities for a thriving future. Success through
                    personalized career counseling. Our top-notch team of career
                    counselors, specializing in various fields, collaborates
                    with renowned universities to tailor educational paths that
                    align with individual goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
