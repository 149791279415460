import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./KeyPrograme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
const KeyPrograme = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/program-features`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const featureslider = [
    {
      title: "Strategising",
      description:
        "Future workplaces are all about leveraging talent and technology optimally. Learn how to use this to transform existing synergy",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/Outline.png",
    },
    {
      title: "Leadership",
      description:
        "Future workplaces are all about leveraging talent and technology optimally. Learn how to use this to transform existing synergy",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/leader.png",
    },
    {
      title: "Analyses",
      description:
        "Future workplaces are all about leveraging talent and technology optimally. Learn how to use this to transform existing synergy",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/analysis.png",
    },
    {
      title: "Influencing",
      description:
        "Future workplaces are all about leveraging talent and technology optimally. Learn how to use this to transform existing synergy",
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Amity-vniversity/communication.png",
    },
    {
      title: "Problem Solving",
      description:
        "Future workplaces are all about leveraging talent and technology optimally. Learn how to use this to transform existing synergy",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/solution.png",
    },
  ];
  return (
    <>
      <section className="key-program">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center">
              <h5>Key Program Features</h5>
              <div className="border-line mb-3"></div>
              <p>
                Engaging, stimulating—learning that enriches your educational
                journey profoundly.
              </p>
            </div>
          </div>

          <div className="row">
            <Swiper
              spaceBetween={15}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                486: {
                  slidesPerView: 2,
                },
                578: {
                  slidesPerView: 2,
                },

                768: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1400: {
                  slidesPerView: 5,
                },
                1600: {
                  slidesPerView: 5,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12 mt-5">
                    <Card className="mb-5">
                      <div className="image-holder mt-5 text-center ">
                        <img
                          src={IMG_URL + slide?.image}
                          className="amity-img"
                          alt="..."
                        />
                      </div>
                      <Card.Body>
                        <Card.Title>
                          <div className="name-holder ">
                            <h4>{slide?.name}</h4>
                          </div>
                          <div className="midlle">
                            <div class="text  text-center">
                              <p>{parse(slide?.description || "")} </p>
                            </div>
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default KeyPrograme;
