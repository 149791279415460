import { React, useRef, useEffect, useState, useContext } from "react";
import "./DiscoverMca.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import Card from "react-bootstrap/Card";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
const DiscoverMca = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const diffrenceslider = [
    {
      title: "Flexibility Redefined",
      description:
        "Embrace a flexible learning environment that accommodates your busy schedule. Our Online MCA allows you to balance work and education seamlessly, providing the flexibility you need for success.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-3.png",
    },
    {
      title: "Work-Integrated Learning",
      description:
        "Experience a unique blend of theoretical knowledge and practical application. Our work-integrated learn-ing approach ensures that you gain real-world skills, making your education immediately applicable to your profession.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-2.png",
    },
    {
      title: "Cost-Effective Solutions",
      description:
        "Say goodbye to traditional expenses associated with on-campus learning. Our Online MCA offers cost-effective solutions without compromising on the quality of education, making advanced learning accessible to all.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-1.png",
    },
    {
      title: "Innovative Technology",
      description:
        "Immerse yourself in cutting-edge technology, staying ahead in the digital age. Our online platform provides an interactive and engaging learning environment.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-3.png",
    },
    {
      title: "Global Networking Opportunities",
      description:
        "Connect with a diverse community of professionals and experts worldwide. Build invaluable networks for collaboration and career growth.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-2.png",
    },
    {
      title: "Customized Learning Experience",
      description:
        "Tailor your learning journey based on your strengths and interests. Access resources and support that align with your individual goals and aspirations.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-1.png",
    },
    {
      title: "Career Advancement On Your Terms",
      description:
        "Elevate your career at your own pace. Our Online BCA program empowers you to balance professional growth with your current commitments.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/mca/round-img-3.png",
    },
  ];
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/mca/discover`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section className="mca-discover-section">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-10 col-12 mx-auto">
            <div className="heading-holder text-center mb-md-5 mb-sm-3 mb-0">
              <h5>Discover the Power of Online MCA</h5>
              <p>
                <b>Online MCA :</b> Your Path to Career Advancement
              </p>
              <p>
                Welcome to our Online MCA program, where flexibility is
                redefined, and career advancement is tailored to your terms.
                Discover the benefits that make online MCA the ideal choice for
                working professionals.
              </p>
            </div>
          </div>

          <div className="swiper-div">
            <Swiper
              spaceBetween={0}
              modules={[Autoplay, Pagination]}
              pagination={{
                clickable: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                486: {
                  slidesPerView: 1,
                },
                578: {
                  slidesPerView: 1,
                },

                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 3,
                },
              }}
              className="mySwiper  "
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              {data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12">
                    <Card className="">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="content-holder mt-lg-4 mt-md-4 mt-sm-4 mt-3">
                            <h4 className="">{slide.title}</h4>
                            <div className="grey-border"></div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="image-holder  ">
                            <img
                              src={IMG_URL + slide.image}
                              className="amity-img"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                      <Card.Body className="p-0">
                        <div className="text-holder">
                          <p>{parse(slide?.description) || ""}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="main-slider mobile_slider"
              onClick={() => handleMainSliderClick("prev")}
            >
              <div className="main-arrow-back">
                <div className="arrow-back arrowmobile" />
              </div>
            </div>
            <div
              className="main-slider"
              onClick={() => handleMainSliderClick("next")}
            >
              <div className="main-arrow-next">
                <div className="arrow-next arrowmobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DiscoverMca;
