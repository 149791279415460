import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import "./document.css";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import { ShimmerSectionHeader } from "react-shimmer-effects";


const Document = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [showShimmer, setShowShimmer] = useState(true); 

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/admission/admission-documents`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      setShowShimmer(false);
    }, 3000);

    getDataAll();

    return () => clearTimeout(delay);
  }, []);


  return (
    <>
      {showShimmer &&
      <div className="mt-5">
          <ShimmerSectionHeader center />
      </div>
      } 
      {!showShimmer && ( 
      <section className="document">
        <Container fluid></Container>
        <div className="document-content">
          <Container>
            <div className="text-holder-document pt-5">
              <h2 className="title">Mandatory Documents for Admission</h2>
            </div>
            <div className="row">
              <div className="col-lg-7" >
                <ul className="mt-xl-5 mt-lg-0">
                  {data?.map((item) => (
                    <li>{parse(item?.name) || ""}</li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-5">
                <div className="sndd-img-div pt-5">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/admission/fwfw.png"
                    }
                    className="document-banner-img"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
      )}
    </>
  );
};

export default Document;
