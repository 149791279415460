import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from '../../../../../Utils/MyContext/MyContext';
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import "./QualifiedMba.css"
const QualifiedMba = () => {
  const qualifications = [
    { title: 'Academic Qualifications', text: 'Secure your spot by holding a bachelor\'s degree from an accredited institution, showcasing your commitment to academic excellence.', imgSource: process.env.PUBLIC_URL + '/assets/images/Courses/TechProficiency.png' },

    { title: 'Professional Experience', text: 'Elevate your learning experience with relevant work experience, bringing real-world insights to the virtual classroom.', imgSource: process.env.PUBLIC_URL + '/assets/images/Courses/TechProficiency.png' },

    { title: 'Strong Time Management Skills', text: 'Master the art of balancing work, life, and studies, demonstrating your ability to thrive in a flexible online learning environment.', imgSource: process.env.PUBLIC_URL + '/assets/images/Courses/time-management.png' },

    { title: 'Effective Communication Skills', text: 'Engage in meaningful virtual discussions and collaborative projects, highlighting your proficiency in articulating ideas clearly, a crucial skill for success.', imgSource: process.env.PUBLIC_URL + '/assets/images/Courses/negotiation.png' },

    { title: 'Technological Proficiency', text: 'Navigate the digital landscape effortlessly, as online MBA programs demand comfort with technology for seamless participation in virtual classes and collaborative endeavors.', imgSource: process.env.PUBLIC_URL + '/assets/images/Courses/TechProficiency.png' },

  ];
  const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mba/who-qualified`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  
  return (
    <>
      <section className='Qualified'>
        <div className='container'>
          <div className='UpperHEadPart'>
            <h3 className='qualified_head'>Who Qualified For Online MBA?</h3>
            <p>Discover the key qualifications needed to embark on a successful online MBA journey</p>

          </div>

          <div className='row'>
            <div className='col-lg-4 col-md-4'>
              <div className='qualifiedImgSec'>
                <img src={process.env.PUBLIC_URL + "/assets/images/Courses/qualifuedImg.png"} className='Qualifiedimg' />
                <div className='orangeOverlay'></div>
              </div>
            </div>
            <div className='col-lg-8 col-md-8'>
              {data?.map((qualification, index) => (
                <div key={index} className='row QualifiedmainRow'>
                  <div className='col-lg-3 col-md-4'>
                    <div className='qualifiedlefttxt'>
                      <div className='qualifiedIcon'>
                        <img src={IMG_URL+ qualification?.image} />
                      </div>
                      <div>
                        <p className='mb-0'>{qualification?.title}</p>

                      </div>
                    </div>
                  </div>
                  <div className='col-lg-9 col-md-8'>
                    <div className='d-flex qualifiedrighttxt'>
                      <p>{parse(qualification?.description) || ""}</p>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default QualifiedMba