import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import Container from "react-bootstrap/Container";
import "./Nowait.css";
import parse from "html-react-parser";

const Nowait = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/barriers`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="NoWait-Section ">
        <div className="banner-content">
          <div className="baner">
            <img
              className="banner-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/university/dy-patil/Nowait/nowaitBanner.png"
              }
              alt="Logo"
            />
            <div className="overlay-color"></div>
          </div>
          <div className="overlay-text">
            {data?.map((val) => (
              <Container>
                <div className="description">
                  <div className="mb-3">
                    <h3 className="title">{val?.name}</h3>
                    <div className="text-border" />
                  </div>
                  <p>{parse(val?.description)}</p>
                </div>
              </Container>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Nowait;
