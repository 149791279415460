import Modal from 'react-bootstrap/Modal';
import "./AdvantageModal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from "html-react-parser";
function AdvantageModal(props) {
    return (
        <section className=''>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                className='advantageModal'

                centered
                backdropClassName="modal-backdrop-blur"
            >
                <Modal.Header closeButton> <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={props.onHide} className='modalCLosebtn' /></Modal.Header>
                <Modal.Body>
                    <div className='advantageModHead'>
                        <h4>{props.title}</h4>
                        <div className="border-line mb-3"></div>
                        <p>{props.description}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default AdvantageModal;