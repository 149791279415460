import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import "./Mcombanner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
const Mcombanner = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/m-com/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  const { name } = useContext(ImageContext);
  return (
    <section className="Mcom-banner-section">
      <div className="container">
        <div className="m-image-div desktop-view-banner">
          {/* {data?.map((image) => (
                        <img key={image.id} src={IMG_URL+image.image} className='mcom-img' />
                    ))} */}
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data?.map((image, index) => (
              <SwiperSlide>
                <div className="image-div">
                  <img
                    key={image.id}
                    src={IMG_URL + image?.image}
                    className="mcom-img"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="m-image-div mobile-view-banner">
          {/* {data.map((image) => (
                        <img key={image.id} src={IMG_URL+image.image} className='mcom-img' />
                    ))} */}
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data?.map((image, index) => (
              <SwiperSlide>
                <div className="image-div">
                  <img
                    key={image.id}
                    src={IMG_URL + image?.image}
                    className="mcom-img"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Mcombanner;
