import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./drdybanner.css"
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react"
const Drdybanner = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/university/dy-patil/banner-two`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className='drdypatilbanner-section'>

        <div className='container'>
          <div className='title-sec'>
            <h3 className='title abt-title'>Dr. D.Y. Patil University, ..Pune</h3>
            <div className='underline'></div>
            <p className='sub-title'>Offers Online MBA Degree + Value added international courses</p>
          </div>
          {/* {data?.map((val)=>
                <div className='banner-div'>
                    <img className='banner-img' src={IMG_URL+val.image} />
                </div>
                )} */}
          <div className="banner-div desktop-view-banner">
            {/* {name.map((image) => (
              <img key={image.id} src={image.image16} className='banner-img' />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="banner-img-main">
                    <img
                      // key={image?.id}
                      src={IMG_URL + image?.image}
                      className="banner-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="banner-div mobile-view-banner">
            {/* {name.map((image) => (
              <img key={image.id} src={image.image37} className='banner-img' />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="banner-img-main">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="banner-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default Drdybanner