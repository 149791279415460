import React from 'react'
import BaBanner from './Ba-banner/BaBanner'
import OnlineBa from './Online-ba/OnlineBa'
import TopReasonBa from './Top-reason-ba/TopReasonBa'
import SmallBannerBa from './Small-banner-ba/SmallBannerBa'
import WhoQualifiedBa from './Who-Qualified-ba/WhoQualifiedBa'

const Ba = () => {
    return (
        <>
            <BaBanner />
            <OnlineBa />
            <TopReasonBa />
            <SmallBannerBa />
            <WhoQualifiedBa />
        </>
    )
}

export default Ba