import React, { useContext, useEffect, useState } from "react";
import "./Right_guidance.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

import { Pagination, Autoplay } from "swiper/modules";
import { Context } from "../../../../utils/context";
import { allGuidance } from "../../../../utils/apis/common/Common";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ShimmerThumbnail } from "react-shimmer-effects";


const Right_guidance = () => {
  const { IMG_URL } = useContext(Context);
  const [guidance, setGuidance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGuidance = async () => {
    const res = await allGuidance();
    if (res?.success) {
      setGuidance(res?.data);
    }
  };

  useEffect(() => {
    getGuidance();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);


  const popnewslider = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    cssEase: "linear",
    rtl: true, // This sets the slider direction from right to left
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };



  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={600} rounded />
      ) : (
        <section className="Right_guidance_section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="cont-seccc">
                  {/* <h6 className="title"> Say Goodbye to faceless Call Centers </h6> */}
                  <h2 className="title main-tt">Right Guidance from Experts</h2>
                  <p className="subcont">
                    Profcyma has a team of 200+ experts who are assisting you
                    since 2019 and giving you the right guidance for your
                    successful career ahead.
                  </p>
                </div>
              </div>
              <div className="guidanceslider_sec">
                <Slider {...popnewslider}>
                  {guidance.map((item, index) => (
                    <div className="main-divvvv">
                      <Link to={`/ed-tech/stepform/${item?.id}`}>
                        <div className="slider-div" key={index}>
                          <img
                            className="guidanceimg"
                            src={IMG_URL + item?.image}
                            alt={`${item?.name}`}
                          />
                        </div>
                        <div className="guidance-details">
                          <h6 className="name">{item?.name}</h6>
                          <p className="position">
                            Education Expert <span>{item?.expert_in}</span>
                          </p>
                          <p className="experience">
                            {item?.experience} experience
                          </p>
                          <p className="experience">{item?.city}</p>
                          <button className="btn cons-btn">Consult Now</button>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}
    </>

  );
};

export default Right_guidance;
