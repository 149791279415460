import React from 'react';
import './MaSmallbanner.css'
const MaSmallbanner = () => {
    const style = {
        width: '100%',
        paddingBottom: "3%"
    };

    return (
        <>
            <section className='Ma-small-ban-sec'>
                <div className='container'>
                    <div className='row '>
                        <div className='desktopview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Courses/MA/maSmallBanner.png'} style={style} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/M.A.png'} style={style} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MaSmallbanner;
