import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Container } from "react-bootstrap";
import "./Dypartners.css";

const Dypartners = () => {
  const allSecurity = [
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Amdoc.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Amazon.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Accenture.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Airtel.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Cognizant.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Tech.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Prodapt.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Practo.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Paytm.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Ux.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Mphisis.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Federal.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Amdoc.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Mphisis.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Amdoc.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Prodapt.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Practo.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Paytm.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Ux.png",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/university/dy-patil/Partners/Mphisis.png",
    },
  ];

  const [securityPerPage, setsecurityPerPage] = useState(8);
  const totalSlides = Math.ceil(allSecurity.length / securityPerPage);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setsecurityPerPage(4);
      } else if (window.innerWidth <= 991) {
        setsecurityPerPage(6);
      } else {
        setsecurityPerPage(12);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/hiring-partner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="Dy-partner-section">
        <Container>
          <div>
            <div className="heading">
              <h4 className="title">Hiring Partners</h4>
              <div className="text-border" />
              <p>Our Students are Working With</p>
            </div>

            <div className="dy-partner-slider">
              <Swiper
                spaceBetween={50}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{ clickable: true }}
                className="my-swiper"
              >
                {(() => {
                  const slides = [];

                  for (let i = 0; i < totalSlides; i++) {
                    const start = i * securityPerPage;
                    const end = (i + 1) * securityPerPage;
                    const security = data?.slice(start, end);

                    slides.push(
                      <SwiperSlide key={i}>
                        <div className="row mb-5">
                          {security?.map((item) => (
                            <div
                              key={item.id}
                              className="col-lg-2 col-md-4 col-sm-4 col-6  mt-4"
                            >
                              <div className="images-div">
                                <img
                                  src={IMG_URL + item.image}
                                  className="company-images"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>
                    );
                  }

                  return slides;
                })()}
              </Swiper>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Dypartners;
