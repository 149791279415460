import React, { useState, useEffect, useContext } from 'react';
import Mcabanner from './Mcabanner/Mcabanner';
import Onlinemca from './Onlinemca/Onlinemca';
import Mcacard from './Mcacard/Mcacard';
import ReasonMca from './Mcacard/ReasonMca/ReasonMca';
import DiscoverMca from './DiscoverMca/DiscoverMca';
import QualifiedMca from './QuaalifiedMca/QualifiedMca';
import SmallBanMca from './SmallBanMca/SmallBanMca';
import { Context } from "../../../../../utils/context";

import { useParams } from 'react-router-dom';
import Applybanner from './Applybanner/Applybanner';

function Mca() {
  const { id } = useParams();
  console.log(id, "idddddd");
  const { getData } = useContext(Context);
  const [data, setData] = useState({});
  const [courseName, setCourseName] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/common/common-type/course/${id}`
    );
    setData(response?.data);
    setCourseName(response?.data?.name);
  };

  useEffect(() => {
    getDataAll(id);
  }, [id]);

  return (
    <>
      <Mcabanner id={id} courseName={courseName} />
      <Onlinemca id={id} courseName={courseName} />
      <Mcacard id={id} courseName={courseName} />
      <Applybanner />
      <SmallBanMca id={id} courseName={courseName} />
      <ReasonMca id={id} courseName={courseName} />
      <DiscoverMca id={id} courseName={courseName} />
      <QualifiedMca id={id} courseName={courseName} />
      {/* <FeeStructures id={id} courseName={courseName} /> */}
    </>
  );
}

export default Mca;
