import React from "react";
import SpecializationNewBanner from "./SpecializationNewBanner/SpecializationNewBanner";
import SpecializationLeadership from "./SpecializationLeadership/SpecializationLeadership";
import ProgrameHighlight from "./ProgrameHighlight/ProgrameHighlight";
import EligibiltyCriteria from "./EligibiltyCriteria/EligibiltyCriteria";
import MasteringManagement from "./MasteringManagement/MasteringManagement";
import SpecializationSyllabus from "./SpecializationSyllabus/SpecializationSyllabus";
import AdmissionProcedure from "./AdmissionProcedure/AdmissionProcedure";
import { useParams } from "react-router-dom";
import SuggestedCourses from "./SuggestedCourses/SuggestedCourses";
import FeeStructures from "../Courses/common-courses/common/FeeStructures/FeeStructures";
import Applybanner from "../Courses/common-courses/common/Applybanner/Applybanner";
import AddvertisementSec from "./AddvertisementSec/AddvertisementSec";

const SpecializationNew = () => {
  const { id } = useParams();
  return (
    <>
      <SpecializationNewBanner id={id} />
      <SpecializationLeadership id={id} />
      <ProgrameHighlight id={id} />
      <EligibiltyCriteria id={id} />
      <MasteringManagement id={id} />
      <SpecializationSyllabus id={id} />
      <AdmissionProcedure id={id} />
      <FeeStructures id={id} />
      <Applybanner />
      <AddvertisementSec />
      <SuggestedCourses id={id} />
    </>
  );
};

export default SpecializationNew;
