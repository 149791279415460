import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import "./admissionpolicy.css";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";
import { ShimmerSectionHeader } from "react-shimmer-effects";

const AdmissionPolicy = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showShimmer, setShowShimmer] = useState(true); 

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/admission/admission-policy`
    );
    await setData(response?.data);
    setIsLoading(false); 
  };

  useEffect(() => {
       const delay = setTimeout(() => {
      setShowShimmer(false); 
    }, 3000);

    getDataAll();

    return () => clearTimeout(delay); 
  }, []);


  return (
    <>
      {showShimmer && 
        <ShimmerSectionHeader center className="mt-5" />
      } 
      {!showShimmer && ( 
        <section className="admission-policy">
          <div className="text-holder-policy">
            <h2 className="title">Admission Policy</h2>
          </div>
          <Container>
            <div className="policy-content">
              <ul>
                {data?.map((item) => (
                  <li key={item.id}>{parse(item?.name) || ""}</li>
                ))}
              </ul>
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

export default AdmissionPolicy;
