import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./OnlineBca.css"
import ImageContext from '../../../../../Utils/MyContext/MyContext';
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
import { Modal } from "react-bootstrap";

const OnlineBca = () => {

    // become partner modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { online } = useContext(ImageContext);

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/courses/ug-courses/bca/what`);
        await setData(response?.data);
    };

    useEffect(() => {
        getDataAll();

    }, []);
    return (
        <>
            <section className='OnlineBca-section'>
                <div className='main-onlin-sec p-0'>
                    <div className='onlin-img-div '>
                        <img className='onlin-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/bca/banner-2.png"} />
                    </div>
                    <div className='online-content'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
                                    {data?.map((image) => (
                                        <div className='descrptn'>
                                            <h5 className='title'>{image?.name}</h5>
                                            <p>{parse(image?.description) || ""}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                                    {data?.map((image) => (
                                        <div className='video-div'>
                                            <video className='online-video' playsInline autoPlay mute controls >
                                                <source src={IMG_URL + image?.video}></source>
                                            </video>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-btn-div'>
                                <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Become A Partner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BecomePartner />
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default OnlineBca