import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./ProgrammOffered.css";
import parse from "html-react-parser";
const ProgrammOffered = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/program-offered`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="programm-featured">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-5">
              <div className="title-programm-offered">
                <h2>
                  <span>Program</span> Offered
                </h2>
                <p>
                  Choose from various programs offered by us through the online
                  mode.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row">
                {data?.map((val, index) =>
                  index % 2 === 0 ? (
                    <div className="col-md-6 col-sm-6">
                      <div className="card cource-card">
                        <div className="card-img-main">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/university/dy-patil/programm-offered/Icon.png"
                            }
                            className="card-img"
                            alt="..."
                          />
                        </div>
                        <div className="card-title">
                          <h3>{val?.name}</h3>
                          <p>{parse(val?.description)}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-6 col-sm-6 mt-md-5 mt-4">
                      <div className="card cource-card">
                        <div className="card-img-main">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/university/dy-patil/programm-offered/Icon.png"
                            }
                            className="card-img"
                            alt="..."
                          />
                        </div>
                        <div className="card-title">
                          <h3>{val?.name}</h3>
                          <p>{parse(val?.description)}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              {/* {data?.map((val, index) => (
                                <div className='row' key={index}>
                                    {index % 2 === 0 ? (
                                        <div className='col-md-6 col-sm-6'>
                                            <div className='card cource-card'>
                                                <div className='card-img-main'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/programm-offered/Icon.png"} className="card-img" alt="..." />
                                                </div>
                                                <div className='card-title'>
                                                    <h3>MBA</h3>
                                                    <p>Master of Business <br /> Administration (MBA), is the best and highly-valued professional.</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='col-md-6 col-sm-6 mt-md-5 mt-4'>
                                            <div className='card cource-card'>
                                                <div className='card-img-main'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/programm-offered/Icon.png"} className="card-img" alt="..." />
                                                </div>
                                                <div className='card-title'>
                                                    <h3>BBA</h3>
                                                    <p>BBA is a 3-year undergraduate course that deals with providing students knowledge regarding commerce.</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgrammOffered;
