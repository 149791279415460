import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./SliderMba.css";
const SliderMba = () => {
  const slideData = [
    {
      title: "Flexibility in Learning",
      content:
        "Craft your study schedule around your work commitments. Access lectures, materials, and assignments at your convenience, ensuring a perfect balance between career & education.",
    },
    {
      title: "No Compromise on Professional Commitments",
      content:
        "Pursue an advanced degree without interrupting your current job. Our Online MBA allows you to apply the knowledge gained immediately to your workplace, providing practical insights & enhancing your professional skills.",
    },
    {
      title: "Real-World Applicability",
      content:
        "Dive into case studies and projects that mirror real-world business scenarios. Acquire skills directly applicable to your current role, giving you a competitive edge in your industry.",
    },
    {
      title: "Networking Opportunities",
      content:
        "Connect with a diverse cohort of working professionals from various industries. Build a global network, share experiences, and gain insights into different sectors.",
    },
    {
      title: "Customizable Learning Experience",
      content:
        "Tailor your coursework to align with your career goals. Choose electives that resonate with your professional aspirations, ensuring a personalized and impactful educational journey.",
    },
    {
      title: "Dedicated Support for Working Professionals",
      content:
        "Benefit from resources specifically designed for those balancing work and education. Access mentorship programs, career services, and industry insights tailored to the unique needs of working individuals.",
    },
    {
      title: "Career Advancement Opportunities",
      content:
        "Position yourself for career growth and leadership roles. Our Online MBA equips you with the skills and knowledge needed to climb the corporate ladder and achieve your professional objectives.",
    },
  ];
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/mba/advantages`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="SliderMba">
        <div className="container">
          <h2 className="SliderHeading">
            Here's why our Online MBA <br /> stands out for Working
            Professionals
          </h2>
          <Swiper
            spaceBetween={30}
            slidesPerView={4}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            breakpoints={{
              0: { slidesPerView: 1 },
              360: { slidesPerView: 1 },
              576: { slidesPerView: 2, spaceBetween: 10 },
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1200: { slidesPerView: 4 },
              1800: { slidesPerView: 4 },
              2500: { slidesPerView: 4 },
            }}
          >
            {data?.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="card slidercard">
                  <div className="card-body">
                    <h4>{slide.name}</h4>
                    <div className="line"></div>
                    <p>{parse(slide?.description) || ""}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="main-slider mobile_slider"
            onClick={() => handleMainSliderClick("prev")}
          >
            <div className="main-arrow-back">
              <div className="arrow-back arrowmobile" />
            </div>
          </div>
          <div
            className="main-slider"
            onClick={() => handleMainSliderClick("next")}
          >
            <div className="main-arrow-next">
              <div className="arrow-next arrowmobile" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderMba;
