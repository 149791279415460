import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import './AboutText.css'

const AboutText = () => {
    const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/about-us/about`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
    return (
        <>
            <section className='about-text'>
                <div className='container'>
                    <div className='row mt-5 mt-md-0'>
                        <div className='col-md-12'>
                            <div className='title-profcyma-help mt-5 text-center'>
                                {/* <h5>Profcyma's tail</h5>
                                <h2 className='title'>About Us</h2> */}
                                <h2 className='title'>The Profcyma Story</h2>
                            </div>
                        </div>
                        {data?.map((val)=>
                        <div className='col-md-12 mb-5'>
                            <div className='text-holder-about '>
                            <p className='sub-line text-center'>{val?.name}</p>
                                <p className='main-text'>{parse(val?.description) || ""}</p>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutText;