import React from 'react'
import Banner from './bharati-banner/Banner'
import Aboutbharati from './about-bharati/Aboutbharati'
import Programoffered from './program-offered/Programoffered'
import BhartiFeature from './BhartiFeature/BhartiFeture'
import BhartiAdvantage from './BhartiAvtantage/BhartiAdvantage'
import BhartiFaq from './BhartiFaq/BhartiFaq'
import BhartiPartner from './BhartiPartner/BhartiPartner'
import BhartiEducation from './Bharti-education/BhartiEducation'

const Bharati = () => {
  return (
    <>
        <Banner />
        {/* <Aboutbharati /> */}
        <Programoffered />
        <BhartiEducation/>
        <BhartiFeature/>
        <BhartiAdvantage/>
        {/* <BhartiPartner/> */}
        <BhartiFaq/>
    </>
  )
}

export default Bharati