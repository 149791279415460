import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./DiffrenceBetween.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
const DiffrenceBetween = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bba/discover`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const diffrenceslider = [
    {
      title: "Flexibility Redefined",
      description:
        "Our online BBA program offers unparalleled flexibility, allowing you to balance work and education seamlessly.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/wisdom-expertise.png",
    },
    {
      title: "Work-Integrated Learning",
      description:
        "Immerse yourself in a dynamic curriculum that integrates real-world scenarios, providing practical insights for immediate application in your professional journey.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/serious-woman.png",
    },
    {
      title: "Cost-Effective Solutions",
      description:
        "Enjoy a cost-effective approach to higher education without compromising on quality, making online BBA an economical choice for ambitious professionals.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/happy-female.png",
    },
    {
      title: "Innovative Technology",
      description:
        "Immerse yourself in cutting-edge technology, staying ahead in the digital age. Our online platform provides an interactive and engaging learning environment.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/business-peoples.png",
    },
    {
      title: "Global Networking Opportunities",
      description:
        "Connect with a diverse community of professionals and experts worldwide. Build invaluable networks for collaboration and career growth.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/smiling-business.png",
    },
    {
      title: "Customized Learning Experience",
      description:
        "Tailor your learning journey based on your strengths and interests. Access resources and support that align with your individual goals and aspirations.",
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/happy-businesswoman.png",
    },
    {
      title: "Career Advancement On Your Terms",
      description:
        "Elevate your career at your own pace. Our Online BCA program empowers you to balance professional growth with your current commitments.",
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/education-promotion.png",
    },
  ];
  return (
    <>
      <section className="diffrence-between">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center mb-md-5 mb-sm-3 mb-0">
              <h5>Discover the Power of Online BBA</h5>
              <p>
                Explore the world of possibilities with Profcyma – where online
                BBA is not just a degree; it's a pathway to success in the
                evolving business landscape.
              </p>
            </div>

            <Swiper
              spaceBetween={40}
              modules={[Autoplay, Pagination]}
              pagination={{
                clickable: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                486: {
                  slidesPerView: 1,
                },
                578: {
                  slidesPerView: 1,
                },

                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 3,
                },
              }}
              className="mySwiper  "
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              {data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12 mt-5">
                    <Card className="mb-5">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="image-holder  ">
                            <img
                              src={IMG_URL + slide?.image}
                              className="amity-img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="content-holder mt-lg-4 mt-md-4 mt-sm-4 mt-3">
                            <h4 className="">{slide.name}</h4>
                          </div>
                        </div>
                      </div>
                      <Card.Body>
                        <div className="text-holder">
                          <p>{parse(slide?.description) || ""}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="main-slider mobile_slider"
              onClick={() => handleMainSliderClick("prev")}
            >
              <div className="main-arrow-back">
                <div className="arrow-back arrowmobile" />
              </div>
            </div>
            <div
              className="main-slider"
              onClick={() => handleMainSliderClick("next")}
            >
              <div className="main-arrow-next">
                <div className="arrow-next arrowmobile" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiffrenceBetween;
