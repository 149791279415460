import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import "./MAcards.css";
import Modal from 'react-bootstrap/Modal';
const MAcards = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [videoSrc, setVideoSrc] = useState("");
    const { getData, IMG_URL } = useContext(Context);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    const [videodata, setVideoData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/ma/advantages`);
        setData(response?.data);

        const response1 = await getData(`/without-login/ed-tech/courses/pg-courses/ma/video`);
        setVideoData(response1?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    const handleShowModal = (videoSrc) => {
        setShowModal(true);
        setVideoSrc(videoSrc);
    };
   console.log(data);
   const handleCloseModal = () => {
    setShowModal(false);
};
  
    useEffect(() => {
      getDataAll();
    }, []);
    return (
        <>
            <section className='MAcards'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='row MAcardsrow'>
                                {data?.map((val)=>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='card card-body maCardsec upper-card'>
                                        <div>
                                            <img src={IMG_URL+val.image} className='offered_img' />
                                        </div>
                                        <p className='cardPara'>{parse(val?.description) || ""}</p>
                                    </div>
                                </div>
                                )}
                                {/* <div className='col-lg-6 col-md-6 mt-0 mt-md-4'>
                                    <div className='card card-body maCardsec down-card'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offered_img' />
                                        </div>
                                        <p className='cardPara'>Our programs offer interactive courses, connecting you with industry experts.</p>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6'>
                                    <div className='card card-body maCardsec upper-card'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offered_img' />
                                        </div>
                                        <p className='cardPara'><b>Join a Vibrant Community :</b> Redefine your future through flexible education and networking opportunities.</p>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 mt-0 mt-md-4  '>
                                    <div className='card card-body maCardsec down-card'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offered_img' />
                                        </div>
                                        <p className='cardPara'>Limitless Possibilities Await: Discover your future with our flexible and accessible Online M.A. programs.</p>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        {videodata?.map((val)=>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                            
                            <div className='blackStrip'>
                                <div className='playbtn' >
                                    <img  src={process.env.PUBLIC_URL + "/assets/images/Courses/MA/play_btn.png"} className='platbtnImg' onClick={() => handleShowModal(IMG_URL + val?.video)}/>
                                </div>
                            </div>
                          

                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/Courses/MA/card_img.png"} className='MACardImg' />
                            </div>
                        </div>
                         )} 
                    </div>

                </div>
            </section>
            <Modal className='modal-video' show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='round-video'>
                        <video className='online-video' playsInline autoPlay muted controls>
                            <source src={videoSrc}></source>
                        </video>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal className='modal-video' show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='round-video'>
                        <video className='online-video' playsInline autoPlay mute controls >
                            <source src={videoSrc}></source>
                        </video>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default MAcards