import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./wedeliver.css";
import parse from "html-react-parser";

const Wedeliver = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/future`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="we-deliver-section">
        <div className="container">
          {data?.map((val) => (
            <div className="contttt-div">
              <h3 className="title wede-title">{val?.name}</h3>
              <p className="wede-cont">{parse(val?.description)}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Wedeliver;
