import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import ImageContext from "../../../../../Utils/MyContext/MyContext";
import "./Banner.css";
const Banner = () => {
  const { name } = useContext(ImageContext);
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/mba/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="Courses_mba_banner">
        <div className="container">
          <section className="home-banner-section">
            <div className="">
              <div className="slider-section desktop-view-banner">
                <div className="banner-slide-div">
                  {/* <div className='image-div'>
                    {data?.map((val) => (
                      <img key={val.id} src={IMG_URL+val.image} className='banner-img1' />
                    ))}
                  </div> */}
                  <Swiper
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {data?.map((image, index) => (
                      <SwiperSlide>
                        <div className="image-div">
                          <img
                            key={image.id}
                            src={IMG_URL + image?.image}
                            className="banner-img1"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="slider-section mobile-view-banner">
                <div className="banner-slide-div">
                  {/* <div className='image-div'>
                    {name.map((image) => (
                      <img key={image.id} src={image.image41} className='banner-img1' />
                    ))}
                  </div> */}
                  <Swiper
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {data?.map((image, index) => (
                      <SwiperSlide>
                        <div className="image-div">
                          <img
                            key={image.id}
                            src={IMG_URL + image?.image}
                            className="banner-img1"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Banner;
