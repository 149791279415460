import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import "./WhoQualified.css";
const WhoQualified = () => {
  const qualificationData = [
    {
      title: "Academic Qualifications",
      iconSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/MA/academic.png",
      description:
        "Elevate your career with a bachelor's degree from an accredited institution. While a business-related background is beneficial, diverse fields are welcomed",
    },
    {
      title: "Professional Experience",
      iconSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/MA/Professional.png",
      description:
        "Our program values seasoned professionals. Demonstrate your expertise with a minimum of several years of work experience, providing a rich foundation for collaborative learning.",
    },
    {
      title: "Strong Time Management Skills",
      iconSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/MA/Technological.png",
      description:
        "Master the art of balance. Our online M.A. demands effective time management, allowing you to integrate studies into your busy professional life seamlessly.",
    },
    {
      title: "Effective Communication Skills",
      iconSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/MA/time-management.png",
      description:
        "Hone your ability to articulate ideas. Engage in vibrant virtual discussions, written assignments, and collaborative projects to enhance your communication prowess.",
    },
    {
      title: "Technological Proficiency",
      iconSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/MA/negotiation.png",
      description:
        "Navigate the digital landscape effortlessly. Embrace technology with confidence, ensuring a smooth online learning experience tailored for working professionals.",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/ma/who-qualified`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="who-qualified-ma">
        <div className="container">
          <div className="row justify-content-center">
            <div className="heading-holder text-center my-5">
              <h3 className="title">Who Qualified For Online M.A.?</h3>
              <p>
                Fuel your ambition and career growth. Enroll now to shape your
                future with our online M.A. program.
              </p>
            </div>

            <div className="row justify-content-center align-items-center">
              {data?.map((qualification, index) => (
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="card mb-5">
                    <div className="border-circle mx-auto mb-3">
                      <div className="orange-circle">
                        <img
                          className="icon-img"
                          src={IMG_URL + qualification.image}
                          alt={`icon-${index}`}
                        />
                      </div>
                    </div>
                    <div className="border-effect-ma">
                      <img
                        className="border-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Courses/MA/Path1.png"
                        }
                        alt={`path-img-${index}`}
                      />
                      <div className="text-heading text-center">
                        <h4>{qualification.title}</h4>
                        <p>{parse(qualification?.description) || ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoQualified;
