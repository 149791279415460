import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./banner.css";
import ImageContext from "../../../../Utils/MyContext/MyContext";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Banner = ({ id, universityName }) => {
  const HtmlToReactParser = require('html-to-react').Parser;
  const { name } = useContext(ImageContext);
  const { getData, IMG_URL } = useContext(Context);

  // State to manage data fetching and loading
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  // Fetch banner data
  useEffect(() => {
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/university/common/banner/${id}`);
        setData(response?.data || []);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      } finally {
        setLoading(false); 
      }
    };

    getDataAll();
  }, [id, getData]);

  // Fetch about data
  const [dataAbout, setDataAbout] = useState();

  useEffect(() => {
    const getDataAllAbout = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/university/common/about/${id}`);
        setDataAbout(response?.data);
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };

    getDataAllAbout();
  }, [id, getData]);

  return (
    <>
      {loading && <ShimmerThumbnail height={350} rounded />} 

      {data && data.length > 0 && (
        <section className="dy-ban-section">
          <div className="container">
            <div className="banner-div desktop-view-banner">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="banner-img-main">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        alt={image.alt}
                        className="banner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="banner-div mobile-view-banner">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="banner-img-main">
                      <img
                        key={image.id}
                        src={IMG_URL + image?.image}
                        alt={image.alt}
                        className="banner-img"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Banner;
