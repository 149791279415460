import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./featureonline.css";
import parse from "html-react-parser";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const FeatureOnline = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-com/advantages`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  return (
    <>
      <section className="feature-online">
        <div className="container">
          <div className="row">
            <Swiper
              spaceBetween={20} // Adjust the space between slides as needed
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>

                  <div className="main-class-card " key={index}>
                    <div className="orange-border-bg"></div>
                    <div className="feature-bg-card">
                      <p className="feature-text">
                        {parse(item?.description || "")}
                      </p>
                      <div className="graduate-cap-image">
                        <img
                          className="feature-img"
                          src={IMG_URL + item?.image}
                        />
                      </div>
                    </div>
                  </div>

                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureOnline;
