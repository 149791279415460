import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import './bcomreason.css';
import parse from "html-react-parser";

const BcomReason = () => {

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/courses/ug-courses/b-com/resons`);
        await setData(response?.data);
    };

    useEffect(() => {
        getDataAll();
        console.log(data);

    }, []);


    const BcomReasonDetails = [
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/img-1.png",
            headingname: "Adaptable Scheduling",
            headingtext: "Enjoy the flexibility to study at your own pace, accommodating your busy lifestyle.",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/img-2.png",
            headingname: "Budget-Friendly",
            headingtext: "Save on commuting and accommodation costs, making education more accessible.",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/img-3.png",
            headingname: "Enhanced Time Management",
            headingtext: "Learn to balance work, life, and studies effectively, honing essential time management skills.",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/img-4.png",
            headingname: "Increased Course Variety",
            headingtext: "Access a diverse range of courses, allowing you to tailor your education to your specific career goals.",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/Courses/bcom/img-5.png",
            headingname: "Career Advancement Opportunities",
            headingtext: "Gain a competitive edge in the professional world, enhancing your career prospects with a recognized B.Com degree. Elevate your skills, expand your network, and seize new opportunities on your journey to success.",
        },
    ]
    return (
        <>
            <section className='bcom-reason'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-12'>
                            <div className='bcom-reason-title text-center'>
                                <h2 className='title'>Top Reasons to Choose Online B.Com.</h2>
                                <p>Discover the advantages of pursuing an Online B.Com</p>
                            </div>
                        </div>
                        {data?.map((item, index) => (
                            <div className='col-xxl-4 col-xl-4 col-md-6 col-sm-6  col-12' >
                                <div className='main-reason-card ' key={index}>
                                    <div className='row'>
                                        <div className='col-md-3' >
                                            {item?.image && (
                                                <img
                                                    src={IMG_URL + item?.image}
                                                    className="bcom-reason-img"
                                                    alt="..."
                                                />
                                            )}
                                        </div>
                                        <div className='col-md-9' >
                                            <h2 className='reason-heading'>{item?.name}</h2>
                                            <p className='feature-text'>{parse(item?.description || "")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default BcomReason;

