import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./DiplomaQualified.css";
import parse from "html-react-parser";
function DiplomaQualified() {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/who-qualified`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="Diploma-qualified-section">
        <div className="container">
          <div className="heading">
            <h5>Who Qualified For Online Diploma?</h5>
          </div>

          <div className="row main-row">
            {data?.map((val) => (
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4  col-12 d-flex justify-content-center">
                <div className="content-div">
                  <div className="icon-div mx-auto">
                    <div className="center-div">
                      <img class="icon" src={IMG_URL + val?.image}></img>
                    </div>
                  </div>
                  <h6>{val?.name}</h6>
                  <p>{parse(val?.description)}</p>
                  <div className="border-line"></div>
                </div>
              </div>
            ))}
            {/* <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4  col-12 d-flex justify-content-center'>
                            <div className='content-div'>
                                <div className='icon-div mx-auto'>
                                    <div className='center-div'>
                                        <img class="icon" src="/assets/images/Courses/M.Lib/Professional.png"></img>
                                    </div>
                                </div>
                                <h6>Professional Experience</h6>
                                <p>A minimum of several years of work experience is preferred, ensuring a rich blend of academic learning and practical insights</p>
                                <div className='border-line'></div>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4  col-12 d-flex justify-content-center'>
                            <div className='content-div'>
                                <div className='icon-div mx-auto'>
                                    <div className='center-div'>
                                        <img class="icon" src="/assets/images/Courses/M.Lib/Technological.png"></img>
                                    </div>
                                </div>
                                <h6>Strong Time Management Skills</h6>
                                <p>Master the art of balancing work, family, and studies. Exhibit self- discipline and effective time management.</p>
                                <div className='border-line'></div>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4  col-12 d-flex justify-content-center'>
                            <div className='content-div'>
                                <div className='icon-div mx-auto'>
                                    <div className='center-div'>
                                        <img class="icon" src="/assets/images/Courses/M.Lib/time-management.png"></img>
                                    </div>
                                </div>
                                <h6>Effective Communication Skills</h6>
                                <p>Sharpen your communication prowess, vital for virtual discussions and collaborative projects.</p>
                                <div className='border-line'></div>
                            </div>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4  col-12 d-flex justify-content-center'>
                            <div className='content-div'>
                                <div className='icon-div mx-auto'>
                                    <div className='center-div'>
                                        <img class="icon" src="/assets/images/Courses/M.Lib/negotiation.png"></img>
                                    </div>
                                </div>
                                <h6>Technological Proficiency</h6>
                                <p>Navigate seamlessly through virtual classes and collaborative platforms, showcasing your tech-savvy side.</p>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default DiplomaQualified;
