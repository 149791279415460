import React from 'react'
import Banner from './banner/Banner'
import Aboutdpu from './about-dpu/Aboutdpu'
import Nowait from './Nowait/Nowait'
import Questions from './Questions/Questions'
import Wedeliver from './we-deliver/Wedeliver'
import ProgrammOffered from './programm-offered/ProgrammOffered'
import Drdybanner from './dr-dy-banner/Drdybanner'
import Testimonial from './Testimonial/Testimonial'
import Benifits from './Benifits/Benifits'
import Dypartners from './Dypartners/Dypartners'
import ProgrammFeatured from './programm-featured/ProgrammFeatured'
import AdvantageUniversity from './advantage-university/AdvantageUniversity'
import Achievement from './Achievement/Achievement'
import { MyContext } from '../../../Utils/MyContext/MyContext'


const Dypatil = () => {
  return (
    <>
        <Banner />
      <Aboutdpu />
      <Wedeliver />
      <ProgrammOffered />
      <Drdybanner />
      <ProgrammFeatured />
      <AdvantageUniversity />
      <Dypartners />
      <Benifits />
      <Testimonial />
      <Achievement />
      <Questions />
      <Nowait />
    </>
  )
}

export default Dypatil