import { React, useRef, useEffect, useState, useContext } from "react";
import "./Onlinemca.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import BecomePartner from "../../../../become-partner/BecomePartner";
import { Modal } from "react-bootstrap";
function Onlinemca() {
  const { online } = useContext(ImageContext);
  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/mca/what`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="OnlineMca-section">
        <div className="main-onlin-sec">
          <div className="online-content">

            <div className="container">
              {data?.map((val) => (
                <div className="row">
                  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                    <div className="descrptn">
                      <h5 className="title">{val.title}</h5>
                      <p>{parse(val?.description) || ""}</p>
                      
                    </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                    <div className="video-div">
                      <video
                        className="online-video"
                        playsInline
                        autoPlay
                        mute
                        controls
                      >
                        <source src={IMG_URL + val.video}></source>
                      </video>
                    </div>
                  </div>
                </div>
              ))}
              

              <div className='modal-btn-div'>
                <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
          <Modal.Header closeButton>
            <Modal.Title>Become A Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BecomePartner />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Onlinemca;
