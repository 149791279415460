import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./discoverpower.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
import parse from "html-react-parser";

const DiscoverPower = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-com/discover`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const DiscoverDetails = [
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Word-Integrated Learning",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Cost-Effective Solution",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Innovative Technology",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Global Networking Opportunities",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Customized Learning Experience",
    },
    {
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bcom/reading-data.png",
      headingname: "Career Advancement on Your Teams",
    },
  ];
  return (
    <>
      <section className="discover-power">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="title-discover text-center">
                <h2 className="title">Discover the Power of Online B.Com.</h2>
                <p>Online B.Com Program</p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="main-first-image-class">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Courses/bcom/discover.png"
                  }
                  className="discover-power-img"
                  alt="..."
                />
                <div className="text-holder-discover">
                  <h2>Flexibility Redefined</h2>
                  <div className="orange-underline"></div>
                  <p>
                    Embark on your B.Com journey with unparalleled flexibility.
                    Our online program is tailor-made for working professionals,
                    offering the convenience of learning on your terms, anytime,
                    anywhere.
                  </p>
                </div>
                <div className="black-overlay-discover"></div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="discoverSwipermain mb-5">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      // spaceBetween: 20
                    },
                    360: {
                      slidesPerView: 1,
                    },
                    467: {
                      slidesPerView: 2,
                    },
                    567: {
                      slidesPerView: 2,
                    },
                    640: {
                      slidesPerView: 2,
                    },

                    768: {
                      slidesPerView: 3,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                    1440: {
                      slidesPerView: 3,
                    },
                    2000: {
                      slidesPerView: 3,
                    },
                    2560: {
                      slidesPerView: 4,
                    },
                    3000: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {data?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div class="content">
                        <div class="content-overlay"></div>
                        <img
                          src={IMG_URL + item?.image}
                          className="discover-img"
                          alt="..."
                        />
                        <div className="discover-cource-name">
                          <h5 className="title-discover">{item?.name}</h5>
                        </div>
                        <div class="content-details fadeIn-bottom">
                          <button className="read-more-btn">Read More</button>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiscoverPower;
