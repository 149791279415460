import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./ReasonMlib.css";
import Button from "react-bootstrap/Button";

function ReasonMlib() {
  const [show, setShow] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setShow(true);
    setModalIndex(index);
  };
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleMainSliderClick = (direction) => {
    if (direction === "prev") {
      setModalIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setModalIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const slides = [
    {
      imgSrc: "/assets/images/Courses/M.Lib/img-1.png",
      title: "Adaptable Scheduling",
      description:
        "Online M.Lib programs provide you with flexible schedules, allowing you to harmonize education with other commitments seamlessly.",
    },
    {
      imgSrc: "/assets/images/Courses/M.Lib/img-2.png",
      title: "Cost-Effective Learning",
      description:
        "Enjoy high-quality learning without compromising your budget, saving on commute and accommodation expenses.",
    },
    {
      imgSrc: "/assets/images/Courses/M.Lib/img-3.png",
      title: "Efficient Time Management",
      description:
        "Tailor your study hours to suit your lifestyle, fostering effective time management skills crucial for success in academic and professional realms.",
    },
    {
      imgSrc: "/assets/images/Courses/M.Lib/img-4.png",
      title: "Diverse Course Offerings",
      description:
        "Access a broad spectrum of courses and specializations, customizing your education to align with your career goals and personal interests.",
    },
    {
      imgSrc: "/assets/images/Courses/M.Lib/img-5.png",
      title: "Career Growth Opportunities",
      description:
        "Propel your professional journey by obtaining a respected degree while actively contributing to your work or business. Seize the opportunity to excel with an Online M.Lib, unlocking new avenues for career growth.",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/m-lib/resons`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <section className="Mlib-Reasn-section mb-5">
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-7 col-lg-9 col-md-12 col-12 mx-auto">
            <div className="heading-div text-center">
              <h5 className="title">Top Reasons to Choose Online M.Lib.</h5>
              <p>Online M.Lib Programs A Gateway to Success</p>
              <p>
                In the fast-evolving realm of information sciences, pursuing a
                Master of Library and Information Science degree online offers
                unparalleled advantages for distance learners, professionals,
                and those passionate about library sciences.
              </p>
            </div>
          </div>
        </div>
        <Swiper
          breakpoints={{
            0: { slidesPerView: 1 },
            576: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
            1600: { slidesPerView: 4 },
          }}
          spaceBetween={40}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
        >
          {data?.map((slide, index) => (
            <SwiperSlide key={index}>
              {/* <div className='mlib-res-card'>
                                <img className='card-img' src={process.env.PUBLIC_URL + slide.imgSrc} />
                                <div className='overlay-color' />
                                <div className='overlay-text'>
                                    <h6>{slide.title}</h6>
                                </div>
                                <div className='star-img-div'>
                                    <Button className='star-btn' onClick={() => handleShow(index)}>
                                        <img className='star-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/M.Lib/star.png"} />
                                    </Button>
                                </div>
                            </div> */}

              <div class="content mlib-res-card">
                <div class="content-overlay"></div>
                <img className="card-img" src={IMG_URL + slide.image} />

                <div class="content-details fadeIn-top">
                  <Button
                    className="star-btn"
                    onClick={() => handleShow(index)}
                  >
                    <img
                      className="star-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Courses/M.Lib/star.png"
                      }
                    />
                  </Button>
                </div>
                <div className="overlay-text">
                  <h6>{slide.title}</h6>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Modal
          className="mlib-reason-card-modal"
          size="sm"
          show={show}
          onHide={handleClose}
          centered
        >
          <div className="modal-contents">
            <Modal.Header>
              <Modal.Title>
                <h5>{modalIndex !== null ? slides[modalIndex].title : ""}</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <div>
                <p>
                  {modalIndex !== null ? slides[modalIndex].description : ""}
                </p>
              </div>
            </Modal.Body>
            <div className="navigations">
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("prev")}
              >
                <div className="main-arrow-back">
                  <div className="arrow-back arrowmobile" />
                </div>
              </div>
              <Button className="close-btn" onClick={handleClose}>
                <img
                  className="icons"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/cancel.png"
                  }
                />
              </Button>
              <div
                className="main-slider"
                onClick={() => handleMainSliderClick("next")}
              >
                <div className="main-arrow-next">
                  <div className="arrow-next arrowmobile" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
}

export default ReasonMlib;
