import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./topreasondiploma.css";
import { Container, Row, Col } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import parse from "html-react-parser";
const TopReasonDiploma = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/reasons`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="topreason-diploma">
        <div className="title-top-reason">
          <h2>Top Reasons to Choose Online Diploma</h2>
        </div>
        <Container>
          <Swiper
            spaceBetween={5}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className=""
            breakpoints={{
              0: { slidesPerView: 1 },
              360: { slidesPerView: 1 },
              576: { slidesPerView: 2 },
              640: { slidesPerView: 2 },
              768: { slidesPerView: 2 },
              1200: { slidesPerView: 4 },
              1400: { slidesPerView: 5 },
              1800: { slidesPerView: 5 },
              2500: { slidesPerView: 5 },
            }}
          >
            {data?.map((val, index) =>
              index % 2 === 0 ? (
                <SwiperSlide>
                  <div className="mb-5">
                    <div className="main-image-class-third text-center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Courses/diploma/top-reason-diploma/three.png"
                        }
                        className="orange-circle-img"
                        alt="..."
                      />
                      <img
                        src={IMG_URL + val.image}
                        className="icon-img"
                        alt="..."
                      />
                    </div>
                    <div className="pointer"></div>
                    <div className="text-holder-reason-diploma">
                      <h2>{val?.name}</h2>
                      <p>{parse(val?.description)}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ) : (
                <SwiperSlide>
                  <div className="mb-5">
                    <div className="text-holder-reason-diploma">
                      <h2>{val?.name}</h2>
                      <p>{parse(val?.description)}</p>
                    </div>
                    <div className="pointer-four"></div>
                    <div className="main-image-class-fourth text-center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/Courses/diploma/top-reason-diploma/fourth.png"
                        }
                        className="orange-circle-img"
                        alt="..."
                      />
                      <img
                        src={IMG_URL + val.image}
                        className="icon-img"
                        alt="..."
                      />
                    </div>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Container>
      </section>
    </>
  );
};

export default TopReasonDiploma;
