import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./topreason.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import parse from "html-react-parser";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Topreason = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/resons`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const Bsctopreasonslider = [
    {
      title: "Adaptable Scheduling",
      content:
        "Online B.Sc programs empower you with flexible schedules, allowing you to balance education with other commitments seamlessly.",
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bsc/layerone.png",
    },
    {
      title: "Budget-Friendly",
      content:
        "Enjoy cost-effective learning without compromising quality, saving on commute and accommodation expenses.",
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bsc/layertwo.png",
    },
    {
      title: "Enhanced Time Management",
      content:
        "Tailor your study hours to suit your lifestyle, fostering efficient time management skills crucial for success in both academic and professional realms.",
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bsc/layerthree.png",
    },
    {
      title: "Increased Course Variety",
      content:
        "Access a diverse range of courses and specializations, tailoring your education to align with your career goals and personal interests.",
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bsc/layerfour.png",
    },
    {
      title: "Career Advancement Opportunities",
      content:
        "Elevate your professional journey by acquiring a respected degree while still actively contributing to your work or bus-iness. Seize the chance to excel with an Online B.Sc, unlocking new opportunities and propelling your career forward.",
      imageSource:
        process.env.PUBLIC_URL + "/assets/images/Courses/bsc/layerfive.png",
    },
  ];

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  return (
    <>
      <section className="bsc-topreason-section">
        <div className="container">
          <div className="title-div">
            <h2 className="title bsc-top-title">
              Top Reasons to Choose Online B.Sc.
            </h2>
            <p className="subtitle">
              Online B.Sc Programs: A Gateway to Success
            </p>
            <p className="subtitle">
              In today's fast-paced world, pursuing a Bachelor of Science degree
              online offers unparalleled advantages for distance learners,
              entrepreneurs, and working professionals.{" "}
            </p>
          </div>
          <div className="top-res-slider-sec">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              slidesPerView={3}
              centeredSlides={false}
              // autoplay={{
              //     delay: 3000,
              //     disableOnInteraction: false,
              // }}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              navigation={false}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                "0": {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                "768": {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                "991": {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                "1400": {
                  slidesPerView: 3,
                  spaceBetween: 25,
                },
              }}
              className="mySwiper"
            >
              {data?.map((d, index) => (
                <SwiperSlide>
                  <div className="top-res-slide-main mb-5">
                    <div className="msg-icn-main">
                      <p className="msg-icn">{parse(d?.description)}</p>
                      <h2 className="reason-title">
                        <span className="orange-bottom-border">{d.name}</span>
                      </h2>
                      <div className="white-bg"></div>
                      {d.image && (
                        <img
                          src={IMG_URL + d.image}
                          className="reason-img"
                          alt="..."
                        />
                      )}

                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Courses/bsc/downArrow.png"
                          }
                          className="downArrowImg"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("prev")}
                        >
                            <div className="arrow-back" />
                        </div>
                        <div
                            className="main-slider"
                            onClick={() => handleMainSliderClick("next")}
                        >
                            <div className="arrow-next" />
                        </div> */}
            <div
              className="main-slider "
              onClick={() => handleMainSliderClick("prev")}
            >
              <div className="main-arrow-back">
                <div className="arrow-back arrowmobile" />
              </div>
            </div>
            <div
              className="main-slider"
              onClick={() => handleMainSliderClick("next")}
            >
              <div className="main-arrow-next">
                <div className="arrow-next arrowmobile" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Topreason;
