import React from 'react'
import MlibBanner from './MlibBanner/MlibBanner'
import OnlineMlib from './OnlineMlib/OnlineMlib'
import QualifiedMlib from './QualifiedMlib/QualifiedMlib'
import ReasonMlib from './ReasonMlib/ReasonMlib'
import Mlibsmallban from './Mlibsmallban/Mlibsmallban'

function Mlib() {
  return (<>
  
  <MlibBanner/>
  <OnlineMlib/>
  <ReasonMlib/>
  <Mlibsmallban/>
  <QualifiedMlib/>
  
  </>
  )
}

export default Mlib