import React from 'react'
import Bba_Advantages from './bba-advantages/Bba_Advantages'
import DiffrenceBetween from './Diffrence-between/DiffrenceBetween'
import WhoQualified from './who-qualified/WhoQualified'
import Banner from './banner/Banner'
import Onlinebba from './Onlinebba/Onlinebba'
import ChooseBBA from './ChooseBBA/ChooseBBA'
import EnrollBBA from './EnrollBBA/EnrollBBA'
import Small_banner from './small-banner/Small_banner'

const Bba = () => {
  return (
    <>
        <Banner />
      <Onlinebba />
      <ChooseBBA />
      {/* <Bba_Advantages /> */}
      <DiffrenceBetween />
      <Small_banner />
      <WhoQualified />
      <EnrollBBA />


    </>
  )
}

export default Bba