import React from 'react'
import './MscSmall.css'
const MscSmallBanner = () => {
    return (

        <>
            <section className='msc-small-ban-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='bannn-img ' src={process.env.PUBLIC_URL + '/assets/images/Courses/small-banners/small-msc.png'} />
                        </div>

                        <div className='mobileview-small-banner '>
                            <img className='bannn-img ' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/M.Sc.png'} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MscSmallBanner