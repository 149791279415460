import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./AmityEducation.css";
import parse from "html-react-parser";
const AmityEducation = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/section-two`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="AmityEducation">
        {data?.map((slide, index) => (
          <div className="container">
            <div className="EducationheadTextsec">
              <h3>Amity University</h3>
              <div class="border-line mb-3"></div>
              <p>{slide?.name}</p>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" EduimgSec">
                  <div className="amityEduimgMain_1">
                    <img
                      src={IMG_URL + slide?.image_one}
                      className="amityimg1"
                    />
                  </div>
                  <div className="amityEduimgMain_2">
                    <img
                      src={IMG_URL + slide?.image_two}
                      className="amityimg2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="amity_eduPara">
                  <p>
                    {/* The country’s first university to receive the prestigious UGC entitlement for its online programs, Amity University Online is devoted to creating a transformative learning environment. In today’s environment, when content is mobile and available anytime, anywhere, learning is truly a click away. */}
                    {parse(slide?.description)}
                  </p>

                  <p>
                    {/* Bringing together pedagogy, content, and technology, Amity University Online is home to a range of University Grants Commission (UGC) entitled programs meant for any time, anywhere learning. Amity University Online serves the educational aspirations of students across the globe with Master’s, Bachelor’s, Post Graduate Certificates and Certificate programs through its well-researched curriculum, renowned faculty, cutting-edge technology, and close industry-academic partnerships. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default AmityEducation;
