import React from 'react'
import DiscoverMa from './Discover-ma/DiscoverMa'
import WhoQualified from './Who-Qualified/WhoQualified'
import MAbanner from './MAbanner/MAbanner'
import OnlineBanner from './OnlineBanner/OnlineBanner'
import MaSmallbanner from './MaSmallbanner/MaSmallbanner'
import MAcards from './MAcards/MAcards'
import ChooseMa from './ChooseMa/ChooseMa'

const Ma = () => {
    return (
        <>
            <MAbanner />
            <OnlineBanner />
            <MAcards />
            <MaSmallbanner />
            <ChooseMa/>
            <DiscoverMa />
            <WhoQualified />
        </>
    )
}

export default Ma