import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./ProgramOffered.css";
import parse from "html-react-parser";
const ProgramOffered = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/program-offered`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="ProgramOffer">
        <div className="container">
          <div className="programofferheadtxt">
            <h3>Program Offered</h3>
            <div class="border-line mb-3"></div>
            <p>
              Diverse online programs at Amity University provide a convenient
              learning platform
            </p>
          </div>

          <div className="row">
            {data?.map((slide, index) => (
              <div className="col-lg-3 col-md-6">
                <div class="part-1">
                  <div class="losange">
                    <div class="los1">
                      <img
                        src={IMG_URL + slide?.image}
                        className="offerImg_1"
                      />
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/Courses/offeredUpper.png"
                          }
                          className="offerImg_2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content_mainHeading">
                  <h3 className="content_heading">{slide?.name}</h3>
                  <p className="content_para">
                    <p>{parse(slide?.description || "")} </p>
                  </p>
                </div>
              </div>
            ))}

            {/* <div className='col-lg-3 col-md-6'>
              <div class="part">

                <div class="losange">
                  <div class="los1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offerimg.png"} className='offerImg_1' />
                    <div>
                      <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offerImg_2' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='content_mainHeading'>
                <h3 className='content_heading'>BBA</h3>
                <p className='content_para'>Bachelor of Business Administration, can be pursued by students from any stream.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div class="part">

                <div class="losange">
                  <div class="los1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offerimg.png"} className='offerImg_1' />
                    <div>
                      <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offerImg_2' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='content_mainHeading'>
                <h3 className='content_heading'>BCA</h3>
                <p className='content_para'>BCA with Specialisation in Cloud & Security</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div class="part">

                <div class="losange">
                  <div class="los1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offerimg.png"} className='offerImg_1' />
                    <div>
                      <img src={process.env.PUBLIC_URL + "/assets/images/Courses/offeredUpper.png"} className='offerImg_2' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='content_mainHeading'>
                <h3 className='content_heading'>M.Com</h3>
                <p className='content_para'>Master of Commerce is a 2-year (4 semesters) program</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgramOffered;
