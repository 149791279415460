import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./BcaAdvantage.css";
import AdvantageModal from "./AdvantageModal/AdvantageModal";
import parse from "html-react-parser";
const BcaAdvantage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bca/advantages`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const handleKnowMore = (title, description) => {
    setModalContent({ title, description });
    setModalShow(true);
  };
  return (
    <>
      <section className="BcaAdvantage">
        <div className="container">
          <div className="bcaAdvantage_head">
            <h3>Advantages of pursuing an online BCA</h3>
          </div>
          <div className="row cardsss-spaccc  pt-5">
            {data?.map((item) => (
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4  col-6 d-flex justify-content-center">
                <div className="bcacircleDiv ">
                  <div className="imgIcon_1">
                    <img
                      src={IMG_URL + item?.image}
                      className="advantageimg1"
                    />
                  </div>
                  <div className="advantageHead">
                    <h3>{item?.name}</h3>
                    <p
                      onClick={() =>
                        handleKnowMore(item?.name, item?.description)
                      }
                    >
                      Know more
                    </p>

                    <AdvantageModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      title={item?.name}
                      description={parse(item?.description || "")}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BcaAdvantage;
