import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import "./ChooseMa.css";

const ChooseMa = () => {
    // Array of objects representing each card
    const cards = [
        {
            imgsource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/adpt.png",
            title: "Adaptable Scheduling",
            description: "Craft your study timetable, enabling flexibility to balance work and personal commitments seamlessly.",
            hovimg: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Adaptable.png",
        },
        {
            imgsource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/frnd.png",
            title: "Budget-Friendly",
            description: "Enjoy cost-effective education without compromising quality, making advanced degrees accessible to all.",
            hovimg: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Budget.png",
        },
        {imgsource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/increase.png",
            title: "Enhanced Time Management",
            description: "Master the art of time management as you navigate coursework, career, and personal life harmoniously.",
            hovimg: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Enhanced.png",
        },
        {
            imgsource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/increase.png",
            title: "Increased Course Variety",
            description: "Diversify your expertise with a wide array of courses, ensuring a comprehensive and enriched learning experience.",
            hovimg: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Increased.png",
        },
        {
            imgsource: process.env.PUBLIC_URL + "/assets/images/Courses/MA/career-opportunity.png",
            title: "Career Advancement Opportunities",
            description: "Position yourself for success with a career-focused curriculum, equipping you with skills and knowledge to propel your professional journey.",
            hovimg: process.env.PUBLIC_URL + "/assets/images/Courses/MA/Career.png",
        },
    ];

    let counter = 1;

    
    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/ma/resons`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);

    return (
        <>
            <section className='ChooseMba'>
                <div className='chooseimgsec'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Courses/MA/MaTopreasonbanner.png"} className='banner_img' />
                    <div className='row ChoosembaCards'>
                        <div className='col-lg-8 col-md-12'>
                            <div className='choosembaheadersec'>
                                <h3>Top Reasons to Choose Online M.A.</h3>
                                <p>Online M.A. Benefits: Elevate Your Education, Empower Your Career Unlock a world of possibilities with our Online M.A. program, tailored for distance learners, entrepreneurs, and working professionals seeking academic excellence on their terms.</p>
                            </div>

                            <div className='row '>

                                {data?.map((card, index) => (
                                    <div key={index} className='col-lg-4 col-md-6'>
                                        <div className='card'>
                                            <div className='row ps-3 pe-3 pt-3'>
                                                <div className='col-lg-6 col-md-6 col-6'>
                                                    <div className='Choosembanumber'>{counter++}</div>

                                                </div>
                                                <div className='col-lg-6 col-md-6 col-6'>
                                                    <div className='text-end'>
                                                        <img src={IMG_URL+card.image} className='maChoosecardIcon' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card-body'>
                                                <div className='border-content'>
                                                    <h3>{card.title}</h3>
                                                    <div className='grey-border'></div>
                                                </div>
                                                <p>{parse(card?.description) || ""}</p>
                                                <div className='hover-img'>
                                                    <img src={IMG_URL+card.image} className='hoverIcon' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ChooseMa;
