import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./FrequencyQuestion.css";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import parse from "html-react-parser";
const FrequencyQuestion = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/all-faq`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  return (
    <>
      <section className="frequently-question">
        <Container>
          <div className="row">
            <div className="heading">
              <h4 className="title mb-1">Frequently Asked Questions</h4>
              <div className="text-border" />
            </div>

            <div className="questions">
              <Accordion defaultActiveKey="0">
                {data?.map((slide, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{slide?.name}</Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <p>{parse(slide?.description || "")} </p>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}

                {/* <Accordion.Item eventKey="1" className='mt-4'>
                  <Accordion.Header >What are the eligibility Criteria for the Online MBA program?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='mt-4'>
                  <Accordion.Header >Is the Online degree globally accepted?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className='mt-4'>
                  <Accordion.Header >What is the admission process?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe. We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals. With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className='mt-4'>
                  <Accordion.Header >What is the evaluation and assessment pattern?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className='mt-4'>
                  <Accordion.Header >What are payment modes for fees?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6" className='mt-4'>
                  <Accordion.Header className='title'>Is the Online MBA degree recognized by statutory bodies?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className='mt-4'>
                  <Accordion.Header className='title'>Why should one choose Amity University?</Accordion.Header>
                  <Accordion.Body>
                    <p>Amity University Online is a value-based institution with an innovative bent towards education that impacts lives across boundaries.
                      Through immersive technology, ground- breaking methodology and a worldwide community, we constantly endeavour to expand the horizons of minds in a virtual space around the globe.
                      We are also home to a range of University Grants Commission (UGC) recognized programs at Masters, Bachelor, Post Graduate Diploma level for nurturing young professionals.
                      With 225000 students spread across countries with diverse backgrounds and a strong force of highly accomplished faculty,we create an ecosystem buzzing with new ideas and innovations.</p>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FrequencyQuestion;
