import React from 'react'
import './SmallBanMca.css'

function SmallBanMca() {
    
    return (
        <>
            <section className='mca-small-banner-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='mca-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/mca/mscsmallban.png"} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='mca-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Banner/MCA.png"} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SmallBanMca