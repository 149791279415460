import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./AdvantagesAdmission.css";
import parse from "html-react-parser";
const AdvantagesAdmission = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/amity/advantages`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="advantages-admission">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center">
              <h5>Advantages of taking admission in AMITY (PUNE)</h5>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-5 col-5 mt-5">
              <div class="triangle mt-5"></div>
              {data?.map((item, index) => (
                <div className="box-holder">
                  <div className="circle-holder "></div>
                  <div className="">
                    <div className="vl-line"></div>
                  </div>
                  <div className="curve-image">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Amity-vniversity/curve-img.png"
                      }
                      className="curve-img"
                    />
                    <div className="logo-holder">
                      <img src={IMG_URL + item?.image} className="logo-img" />
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className='box-holder'>
                <div className='circle-holder'></div>
                <div className=''>
                  <div className='vl-line'></div>
                </div>
                <div className='curve-image'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/curve-img.png"} className="curve-img" />
                  <div className='logo-holder'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/group2.png"} className="logo-img" />
                  </div>
                </div>
              </div>

              <div className='box-holder'>
                <div className='circle-holder'></div>
                <div className=''>
                  <div className='vl-line'></div>
                </div>
                <div className='curve-image'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/curve-img.png"} className="curve-img" />
                  <div className='logo-holder'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/group3.png"} className="logo-img" />
                  </div>
                </div>
              </div>

              <div className='box-holder'>
                <div className='circle-holder'></div>
                <div className=''>
                  <div className='vl-line'></div>
                </div>
                <div className='curve-image'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/curve-img.png"} className="curve-img" />
                  <div className='logo-holder'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/group4.png"} className="logo-img" />
                  </div>
                </div>
              </div>
              <div className='box-holder'>
                <div className='circle-holder'></div>
                <div className=''>
                  <div className='vl-line'></div>
                </div>
                <div className='curve-image'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/curve-img.png"} className="curve-img" />
                  <div className='logo-holder'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/group5.png"} className="logo-img" />
                  </div>
                </div>
              </div>

              <div className='box-holder'>
                <div className='circle-holder'></div>
                <div className='curve-image'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/curve-img.png"} className="curve-img" />
                  <div className='logo-holder'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/group6.png"} className="logo-img" />
                  </div>
                </div>
              </div>
              <div class='triangle2'></div> */}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-7 col-7  mx-auto mt-3">
              {data?.map((item, index) => (
                <div className="heading-holder mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                  <h6>{item?.name}</h6>
                  <p>{parse(item?.description)}</p>
                </div>
              ))}

              {/* <div className='heading-holder mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5'>
                <h6>Self-Assessment Kit</h6>
                <p>Comprising of MCQ, problems, quizzes, assignments and solutions and setting up the FAQs and clarifications on general misconceptions</p>
              </div>
              <div className='heading-holder mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-5'>
                <h6>Case-Based Learning (CBL)</h6>
                <p>Learning is of value only when one can apply it in real-world scenarios.
                  CBL is a crucial part of our programs hence promoting higher levels of cognition, versatility, and efficient self-guided learning.</p>
              </div>
              <div className='heading-holder mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-4'>
                <h6>Digital Library</h6>
                <p>Learners get access to extensive online articles, case studies, and journals so that they can gain in-depth knowledge of your subjects.</p>
              </div>
              <div className='heading-holder mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-4'>
                <h6>Learning Support</h6>
                <p>We provide learning support that includes program-oriented sessions, academic calendars,
                  live interactive virtual classes, counselling sessions, webinars with industrial experts, and discussion forums.</p>
              </div>
              <div className='heading-holder mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-3'>
                <h6>Live Doubt-Solving Sessions</h6>
                <p>Engage in live sessions with industry experts and mentors for real-time problem-solving.</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvantagesAdmission;
