import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import Myths from './Myths/Myths';
import ReadMoreConclusion from './ReadMoreConclusion/ReadMoreConclusion';
import { ReadMoreSec } from './ReadMoreSec/ReadMoreSec';
import { useParams } from 'react-router-dom'; // Importing useParams hook

function ReadMoreSecond() {
  const { id } = useParams();
  const { getData } = useContext(Context);
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const getDataAllLatest = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/blog/blogs/${id}`);
        setBlogData(response?.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    getDataAllLatest();
  }, [id]);

  return (
    <>
      {blogData && (
        <>
          <div className="container"> 
          <ReadMoreSec blogData={blogData} />
          <Myths blogData={blogData} />
          <ReadMoreConclusion blogData={blogData} />
          </div>
        </>
      )}
    </>
  );
}

export default ReadMoreSecond;
