import React from 'react'
import DiplomaBanner from './banner-diploma/DiplomaBanner'
import DiplomaOnline from './diploma-online/DiplomaOnline'
import VideoDiploma from './Video-diploma/VideoDiploma'
import DiplomaSmallBanner from './small-banner-diploma/DiplomaSmallBanner'
import TopReasonDiploma from './top-reason-diploma/TopReasonDiploma'
import DiscoverDiploma from './discover-diploma/DiscoverDiploma'
import DiplomaQualified from './DiplomaQualified/DiplomaQualified'


const Diploma = () => {
    return (
        <>
            <DiplomaBanner />
            <DiplomaOnline />
            <VideoDiploma />
            <DiplomaSmallBanner />
            <TopReasonDiploma/>
            <DiscoverDiploma/>
            <DiplomaQualified/>           
        </>
    )
}

export default Diploma