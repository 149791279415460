import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./WhoQualified.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "swiper/css";
import parse from "html-react-parser";

const WhoQualified = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bba/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const WhoQualifiedDetails = [
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/who-qualified/academic qualitfication.png",
      headingname: "Academic Qualifications",
      subheadingname:
        "Attain a solid foundation with a high school diploma or equivalent. Our online BBA program welcomes diverse academic backgrounds, providing an inclusive pathway for aspiring business professionals.",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/who-qualified/Professional Experience.png",
      headingname: "Professional Experience",
      subheadingname:
        "Enhance your BBA journey with practical insig-hts. While work experience isn't mandatory, a passion for real-world applications strengthens your candidacy, fostering a richer learning experience.",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/who-qualified/time-management.png",
      headingname: "Strong Time Management Skills",
      subheadingname:
        "Excel in the virtual classroom by demonstrating impeccable time management, and balancing work commitments with academic responsibilities.",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/who-qualified/negotiation.png",
      headingname: "Effective Communication Skills",
      subheadingname:
        "Navigate online discussions and collaborative projects seamlessly, showcasing your ability to articulate ideas coherently.",
    },
    {
      imageSource:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/who-qualified/Technological Proficiency.png",
      headingname: "Technological Proficiency",
      subheadingname:
        "Leverage your tech-savvy prowess to navigate digital platforms effortlessly, ensuring a smooth online learning experience.",
    },
  ];
  return (
    <>
      <section className="who-qualified">
        <div className="container-md">
          <div className="row mt-5">
            <div className="col-md-12 mt-4 mb-5">
              <div className="title-who-qualified text-center mt-5">
                <h2>Who Qualified For Online BBA?</h2>
                <p>Qualifications to Pursue an Online BBA</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-5">
                <div className="who-cll-skider-sec">
                  <Swiper
                    modules={[Pagination]}
                    spaceBetween={50}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      575: {
                        slidesPerView: 2,
                      },
                      640: {
                        slidesPerView: 2,
                      },

                      768: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 3,
                      },

                      2560: {
                        slidesPerView: 3,
                      },
                      3000: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {data?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="sub-main-img-class">
                          <div className="main-img-class mb-5">
                            {item?.image && (
                              <img
                                src={IMG_URL + item?.image}
                                className="cource-qualified-img"
                                alt="..."
                              />
                            )}
                            <div className="qualification-name">
                              <h5>{item?.name}</h5>
                            </div>
                            <div className="sub-text">
                              <p>{parse(item?.description) || ""}</p>
                            </div>
                            <div className="orange-overlay"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoQualified;
