import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./TopReasonBa.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
const TopReasonBa = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/ba/resons`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const courseslider = [
    {
      title: "Adaptable Scheduling",
      description:
        "Our flexible class schedules empower you to balance education with your busy life, ensuring you can tailor your studies to fit your lifestyle.",
      imageSrc: process.env.PUBLIC_URL + "/assets/images/Courses/ba/Layer.png",
    },
    {
      title: "Career Advancement Opportunities",
      description:
        "Elevate your career prospects with a recognized online B.A. degree. Gain a competitive edge in the professional world and unlock new opportunities for advancement.",
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/ba/career-opportunity.png",
    },
    {
      title: "Budget-Friendly",
      description:
        "Enjoy a cost-effective education without compromising quality. Our online B.A. program offers affordability without sacrificing excellence, making education accessible to all.",
      imageSrc:
        process.env.PUBLIC_URL + "/assets/images/Courses/ba/earning.png",
    },
    {
      title: "Enhanced Time Management",
      description:
        "Master the art of time management as you navigate your coursework at your own pace. Online learning allows you to prioritize your responsibilities and maximize productivity.",
      imageSrc:
        process.env.PUBLIC_URL +
        "/assets/images/Courses/ba/customer-service.png",
    },
    {
      title: "Increased Course Variety",
      description:
        "Choose from a diverse range of courses, expanding your knowledge base beyond traditional boundaries. Tailor your curriculum to align with your passions and career goals.",
      imageSrc: process.env.PUBLIC_URL + "/assets/images/Courses/ba/layer4.png",
    },
  ];
  return (
    <>
      <section className="top-reason-ba">
        <div className="container">
          <div className="row">
            <div className="heading-holder text-center">
              <h4 className="title">Top Reasons to Choose Online B.A.</h4>
              <p>
                Embark on a transformative educational journey that adapts to
                your life, fosters financial feasibility, hones time management
                skills broadens your academic horizons, and propels your career
                forward.
              </p>
            </div>

            <Swiper
              spaceBetween={20}
              modules={[Autoplay, Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },

                486: {
                  slidesPerView: 2,
                },

                768: {
                  slidesPerView: 2,
                },

                1024: {
                  slidesPerView: 3,
                },

                1440: {
                  slidesPerView: 4,
                },
              }}
              className="mySwiper  "
            >
              {data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-12 mt-md-5 mt-3">
                    <Card className="mb-5">
                      <div className="d-flex">
                        <div className="border-line mt-3"></div>
                        <div className="content-holder ">
                          <h5 className="ps-2">{slide?.name}</h5>
                        </div>
                        <div className="image-holder  text-center">
                          <img
                            src={IMG_URL + slide.image}
                            className="course-img"
                            alt="..."
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <div className="text-holder">
                          <p>{parse(slide?.description || "")}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopReasonBa;
