import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "swiper/css";
import "swiper/css/navigation";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "./discoverbsc.css";
import parse from "html-react-parser";

const DiscoverBsc = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/discover`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const slidesData = [
    {
      imageSource: "/assets/images/Courses/bsc/test-img.jpg",
      heading: "Flexibility Redefined",
      content:
        "Embark on a flexible learning journey with our online B.Sc programs, designed for the modern working professional. Tailor your education around your schedule and commitments.",
    },
    {
      imageSource: "/assets/images/Courses/bsc/test-img.jpg",
      heading: "Work-Integrated Learning",
      content:
        "Experience the benefits of work-integrated learning, seamlessly blending academic knowledge with real-world application. Our online B.Sc programs ensure relevance to your professional context.",
    },
    {
      imageSource: "/assets/images/Courses/bsc/test-img.jpg",
      heading: "Cost-Effective Solutions",
      content:
        "Unlock cost-effective educational solutions with our online B.Sc programs. Save on commuting and accommodation expenses while gaining access to quality education from the comfort of your home or office.",
    },
    {
      imageSource: "/assets/images/Courses/bsc/test-img.jpg",
      heading: "Flexibility Redefined",
      content:
        "Embark on a flexible learning journey with our online B.Sc programs, designed for the modern working professional. Tailor your education around your schedule and commitments.",
    },
    {
      imageSource: "/assets/images/Courses/bsc/test-img.jpg",
      heading: "Work-Integrated Learning",
      content:
        "Experience the benefits of work-integrated learning, seamlessly blending academic knowledge with real-world application. Our online B.Sc programs ensure relevance to your professional context.",
    },
  ];

  return (
    <>
      <section className="discover-bsc">
        <Container fluid>
          <div className="power-main">
            <Container>
              <div className="top-contents">
                <h2>Discover the Power of Online B.Sc.</h2>
                <p>
                  Welcome to Profcyma-Empowering Careers with Online B.Sc.
                  Programs
                </p>
              </div>
              <div className="swiper-contents">
                <Swiper
                  slidesPerView={3}
                  spaceBetween={80}
                  pagination={{
                    clickable: true,
                  }}
                  initialSlide={1}
                  centeredSlides={true}
                  modules={[Pagination]}
                  className=""
                  breakpoints={{
                    0: { slidesPerView: 1 },
                    360: { slidesPerView: 1 },
                    576: { slidesPerView: 2, spaceBetween: 10 },
                    640: { slidesPerView: 2 },
                    768: { slidesPerView: 3 },
                    1200: { slidesPerView: 3 },
                    1800: { slidesPerView: 3 },
                    2500: { slidesPerView: 4 },
                  }}
                >
                  {data?.map((slide, index) => (
                    <SwiperSlide>
                      <div className="main-cards">
                        <Card className="discover-card">
                          <div className="bsccard-overlay">
                            <img
                              src={IMG_URL + slide.image}
                              className="discover-bg-image"
                              alt="..."
                            />
                          </div>

                          <Card.Body>
                            <Card.Text className="Bsctextdiscover">
                              <h3 className="heading-discover">{slide.name}</h3>
                              <p className="content-last">
                                {parse(slide.description)}
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Container>
          </div>
        </Container>
      </section>
      <Container>
        <div className="msc-main-small-banner ">
          <div className="desktopview-small-banner">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/Courses/bsc/bsc-image.png"
              }
              className="msc-smallbanner-img"
              alt="..."
            />
          </div>
        </div>

        <div className="msc-main-small-banner ">
          <div className="mobileview-small-banner">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/Mobile-Banner/Banner/B.Sc.png"
              }
              className="msc-smallbanner-img"
              alt="..."
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default DiscoverBsc;
