import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./ChooseBBA.css";
import LazyLoad from "react-lazyload";
import parse from "html-react-parser";
const ChooseBBA = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bba/resons`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="Choosebba">
        <div className="container-fluid">
          <div className="choosebbaRow"></div>
          <div className="row hdddddd">
            <div className="col-lg-5 col-md-5 col-sm-5 col-12">
              {data?.map((d) => (
                <div className="ChooseImg_sec">
                  {/* <img src={process.env.PUBLIC_URL + "/assets/images/Courses/OnlibebbaCollab.png"} className='choosebbaCollab' />
                   */}

                  <LazyLoad>
                    <img
                      loop
                      autoPlay
                      className="choosebbaCollab"
                      src={IMG_URL + d?.image}
                    ></img>
                  </LazyLoad>
                </div>
              ))}
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7 col-12 my-auto">
              {data?.map((d) => (
                <div className="choosebbaLeft">
                  <h3>{d?.name}</h3>
                  {/* <p>Explore the benefits of pursuing an Online Bachelor’s in Business Administration to enhance your career prospects. This flexible program enables you to continue working while obtaining a valuable professional qualification. Simply prioritize timely study and assignment submissions to meet all course requirements.</p> */}
                  <p>{parse(d?.description) || ""}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseBBA;
