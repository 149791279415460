import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './TopReasonMsc.css'
const TopReasonmsc = () => {
    const courseData = [
        {
            title: 'Adaptable Scheduling',
            description: 'Online M.Sc programs empower you with flexible schedules, allowing you to balance education with other commitments seamlessly.',
            icon: '/assets/images/Courses/msc/msc1.png'
        },
        {
            title: 'Budget-Friendly',
            description: 'Enjoy cost-effective learning without compromising quality, saving on commute and accommodation expenses.',
            icon: '/assets/images/Courses/msc/msc2.png'
        },
        {
            title: 'Enhanced Time Management',
            description: 'Tailor your study hours to suit your lifestyle, fostering efficient time management skills crucial for success in both academic and professional realms.',
            icon: '/assets/images/Courses/msc/msc3.png'
        },
        {
            title: 'Increased Course Variety',
            description: 'Access a diverse range of courses and specializations, tailoring your education to align with your career goals and personal interests.',
            icon: '/assets/images/Courses/msc/msc4.png'
        },
        {
            title: 'Career Advancement Opportunities',
            description: 'Elevate your professional journey by acquiring a respected degree while still actively contributing to your work or business. Seize the chance to excel with an Online M.Sc, unlocking new opportunities and propelling your career forward.',
            icon: '/assets/images/Courses/msc/msc5.png'
        }
    ];
    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/msc/resons`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);
    return (
        <>
            <section className='top-reason-msc'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder text-center mb-5'>
                            <h4 className='title'>Top Reasons to Choose Online M.Sc. </h4>
                            <p>Online M.Sc Programs A Gateway to Success</p>
                            <p>In today's fast-paced world,
                                pursuing a Master of Science degree online offers unparalleled advantages for distance
                                learners, entrepreneurs, and working professionals.</p>
                        </div>

                        {data?.map((card, index) => (
                            <div className='col-lg-4 col-md-6' key={index}>
                                <div className='card-box mb-5'>
                                    <div className='bg-circle mb-3'>
                                        <img className='icon-img' src={IMG_URL+card.image} alt={card.title} />
                                    </div>
                                    <div className='content-holder text-center'>
                                        <h5>{card.title}</h5>
                                        <p>{parse(card?.description) || ""}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default TopReasonmsc