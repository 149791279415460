import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../utils/context";
import './SpecializationNewBanner.css';
import { Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 
import { ShimmerThumbnail } from 'react-shimmer-effects';

const SpecializationNewBanner = ({ id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/specialization/banner/${id}`);
            setData(response?.data || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section className="specialization-banner">
            <Container>
                <Row>
                    <div className='banner-image'>
                        {loading && <ShimmerThumbnail height={400} rounded />}
                        {!loading && (
                            <Swiper
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                className="mySwiper"
                            >
                                {data.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            className="ban-img"
                                            src={IMG_URL + image?.image}
                                            alt=""
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </Row>
            </Container>
        </section>
    );
};

export default SpecializationNewBanner;
