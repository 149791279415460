import React, { useContext, useEffect, useState } from "react";
import "./OurAluminis.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { ourAlumniPerson } from "../../../../utils/apis/common/Common";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ShimmerThumbnail } from "react-shimmer-effects";

const OurAluminis = () => {
  const { IMG_URL } = useContext(Context);
  const [alumni, setAlumni] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDataAll = async () => {
    const res = await ourAlumniPerson();
    if (res?.success) {
      setAlumni(res?.data);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const popnewslider = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <ShimmerThumbnail height={600} rounded />
      ) : (
        <section className="OurAluminis">
          <div className="container">
            <div className="AluminiMain">
              <div className="aluminiheadmain">
                <h3>Our Alumni</h3>
                <p>Successful Careers Start Here</p>
              </div>
              <Slider {...popnewslider}>
                {alumni?.map((item) => (
                  <div className="white-bgggg" key={item?.id}>
                    <div className="aluminiImgcont">
                      <img
                        src={IMG_URL + item?.image}
                        alt={`Alumini ${item?.id}`}
                      />
                      <div className="overlay">
                        <div className="image-app">
                          <img
                            src={IMG_URL + item?.logo}
                            className="companyimg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <p className="name-alumini">{item?.name}</p>
                      <div className="mx-auto d-flex justify-content-center">
                        <img
                          src={IMG_URL + item?.logo}
                          className="commmm-img"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="aluminibtnsec text-center">
              <Link to={"/ed-tech/stepform/:id"}>
                <Button className="careerbtn" type="button">
                  Click here to make your career{" "}
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </Button>
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default OurAluminis;
