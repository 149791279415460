import React from 'react'
import AmityBanner from './Amity-banner/AmityBanner'
import AmityEducation from './Amity-Education/AmityEducation'
import OnlineCourse from './online-course/OnlineCourse'
import ProgramOffered from './programe-offered/ProgramOffered'
import InternationalCourse from './International-course/InternationalCourse'
import KeyPrograme from './Key-programe/KeyPrograme'
import AdvantagesAdmission from './advantages-admission/AdvantagesAdmission'
import HiringPartner from './hiring-partner/HiringPartner'
import FrequencyQuestion from './Frequency-question/FrequencyQuestion'
import AmitySmallBanner from './AmitySmallBanner/AmitySmallBanner'
import AchievementAmity from './Achievement-amity/AchievementAmity'

const AmityUniversity = () => {
    return (
        <>
            <AmityBanner />
            <AmityEducation />
            <OnlineCourse />
            <ProgramOffered />
            <InternationalCourse />
            <KeyPrograme />
            <AmitySmallBanner />
            <AdvantagesAdmission />
            <AchievementAmity />
            {/* <HiringPartner /> */}
            <FrequencyQuestion />
        </>
    )
}

export default AmityUniversity