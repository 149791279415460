import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./Onlinebba.css";
import LazyLoad from "react-lazyload";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
import { Modal } from "react-bootstrap";

const Onlinebba = () => {

  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { online } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bba/what`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="Onlinebba">
        <div class="triangle1"></div>
        <div className="container">
          <div className="row onlinebbaRow">
            <div className="col-lg-6 col-md-6 my-auto">
              {data?.map((d) => (
                //   <img key={image.id} src={image.image8} className='bca-img' />
                <div className="onlinebbaLeft">
                  <h3>{d?.name}</h3>
                  <p>{parse(d?.description) || ""}</p>
                </div>
              ))}
              {/* <h3>What is Online BBA?</h3>
                                <p>Discover the flexibility of our Online BBA program, tailored for distance learners, entrepreneurs, and working professionals. Our comprehensive curriculum ensures a solid foundation in business principles, while the convenience of online learning allows you to balance education with your busy schedule</p> */}
            </div>
            <div className="col-lg-6 col-md-6 my-auto">
              <div className="Onlineimg_sec">
                <div class="categorie-div">
                  <div class="top-card-bd"></div>
                  <div class="top-card-bddd"></div>
                  {data?.map((d) => (
                    <div class="img-div">
                      <LazyLoad>
                        <video
                          loop
                          autoPlay
                          muted
                          className="onlinebbaCollab"
                          src={IMG_URL + d.video}
                        ></video>
                      </LazyLoad>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='modal-btn-div'>
            <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
          </div>
        </div>

        <div class="triangle"></div>

        <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
          <Modal.Header closeButton>
            <Modal.Title>Become A Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BecomePartner />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default Onlinebba;
