import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './QualifiedMlib.css';

function QualifiedMlib() {

    const slideItems = [
        {
            heading: 'Academic Qualifications',
            description: 'Enhance your expertise with a comprehensive M.Lib. program online, tailored for individuals with a solid academic foundation.',
            imagePath: '/assets/images/Courses/M.Lib/academic.png'
        },
        {
            heading: 'Professional Experience',
            description: 'Leverage your professional background as you pursue an online M.Lib., integrating theoretical knowledge with practical experience for a holistic learning journey.',
            imagePath: '/assets/images/Courses/M.Lib/Professional.png'
        },
        {
            heading: 'Strong Time Management Skills',
            description: 'Our flexible online platform accommodates your schedule, allowing you to master advanced concepts while maintaining a balance with your professional commitments.',
            imagePath: '/assets/images/Courses/M.Lib/Technological.png'
        },
        {
            heading: 'Effective Communication Skills',
            description: 'Hone your communication prowess through interactive online sessions, collaborative projects, and engaging discussions, preparing you for leadership roles.',
            imagePath: '/assets/images/Courses/M.Lib/time-management.png'
        },
        {
            heading: 'Academic Qualifications',
            description: 'Enhance your expertise with a comprehensive M.Lib. program online, tailored for individuals with a solid academic foundation.',
            imagePath: '/assets/images/Courses/M.Lib/negotiation.png'
        },

    ];

    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/m-lib/who-qualified`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);

    return (
        <section className='Mlib-Qualified-section'>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-12 col-12 mx-auto'>
                        <div className='heading-div text-center'>
                            <h5 className='title'>Who Qualified For Online M.Lib.?</h5>
                            <p>Are you a working professional aspiring to earn your M.Lib. online? Unlocking new opportunities is within reach with the right qualifications. Ensure</p>
                            <p>Earn Your M.Lib. Online with Academic Excellence</p>
                        </div>
                    </div>
                </div>
                <div className='mlib-swiper-div'>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1600: {
                                slidesPerView: 4,
                            },
                        }}
                        spaceBetween={0}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className='quali-mlib-card'>
                                    <div className='icon-div mx-auto'>
                                        <div className='center-div'><img className='icon' src={IMG_URL+item.image} alt={`icon-${index}`} /></div>
                                    </div>

                                    <div className='arrow-pointer'>
                                        <div className='card-heading'>
                                            <h3>{item.title}</h3>
                                        </div>
                                    </div>
                                    <div className='descript'>
                                        <p>{parse(item?.description) || ""}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className='bottom-title'>
                    <p>Explore the future of library sciences and career growth <b>– Enroll now!</b></p>
                </div>
            </div>
        </section>
    );
}

export default QualifiedMlib;
