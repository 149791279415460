import React from 'react'

const SmallBannerBa = () => {
  return (
    <>
      <section className='bba-small-ban-sec'>
        <div className='container'>
          <div className='row'>
            <div className='desktopview-small-banner'>
              <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Courses/small-banners/ba-small-banner.png'} />
            </div>

            <div className='mobileview-small-banner'>
              <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/images/Mobile-Banner/Banner/B.A.png'} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SmallBannerBa