import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./Banner.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const Banner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bsc/banner`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="bsc-banner-section">
        <div className="container">
          <div className="course-baner-div desktop-view-banner">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="course-baner-div mobile-view-banner mt-3">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
