import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './Cardsmcom.css'

function Cardsmcom() {
    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/pg-courses/m-com/advantages`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='mcom-cards-section'>
                <div className='container'>
                    <div className='all-cards'>

                        <div className='row'>
                            {data?.map((val, index) => (
                                index % 2 === 0 ? (
                                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                        <div className='main-card upper-card' key={index}>
                                            <div className='card-content'>
                                                <p><b>{parse(val?.description) || ""}</b> </p>
                                            </div>
                                            <div className='icon-div'>
                                                <img className='eduicon' src={IMG_URL + val.image} />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                        <div className='main-card down-card' key={index}>
                                            <div className='card-content'>
                                                <p>{parse(val?.description) || ""}</p>
                                            </div>
                                            <div className='icon-div'>
                                                <img className='eduicon' src={IMG_URL + val.image} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                            {/* {data?.map((val ,index)=>
                        <>
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='main-card upper-card' key={index%2==0}>
                                    <div className='card-content'>
                                        <p><b>{parse(val?.description) || ""}</b> </p>
                                    </div>
                                    <div className='icon-div'>
                                        <img className='eduicon' src={IMG_URL+val.image} />
                                    </div>
                                </div>
                            </div>
                            
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='main-card down-card' key={index%2 ==1}>
                                    <div className='card-content'>
                                        <p>{parse(val?.description) || ""}</p>
                                    </div>
                                    <div className='icon-div'>
                                        <img className='eduicon' src={IMG_URL+val.image} />
                                    </div>
                                </div>
                            </div>
                            </>
                             )} */}
                            {/*<div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='main-card upper-card'>
                                    <div className='card-content'>
                                        <p>Tailored schedule, comprehensive curriculum—Online M.Com. for career-driven professionals.</p>
                                    </div>
                                    <div className='icon-div'>
                                        <img className='eduicon' src={process.env.PUBLIC_URL + "/assets/images/icons/mortarboard2.png"} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                <div className='main-card down-card'>
                                    <div className='card-content'>
                                        <p><b> Online M.Com. experience :</b> Dive into commerce expertise effortlessly, advance your career with ease</p>
                                    </div>
                                    <div className='icon-div'>
                                        <img className='eduicon' src={process.env.PUBLIC_URL + "/assets/images/icons/mortarboard2.png"} />
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Cardsmcom