import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./onlinebcom.css";
import Modal from "react-bootstrap/Modal";
import ImageContext from "../../../../../Utils/MyContext/MyContext";
import parse from "html-react-parser";
import BecomePartner from '../../../../become-partner/BecomePartner';
const OnlineBcom = () => {
  const { online } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-com/what`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [showone, setShowone] = useState(false);
  const handleCloseone = () => setShowone(false);
  const handleShowone = () => setShowone(true);
  return (
    <>
      <section className="online-bcom">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="image-div text-center">
                <img
                  className="bcom-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Courses/bcom/young-person-during-intership.png"
                  }
                />
                <div className="brown-backround">
                  <img
                    className="round-img-class"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/Courses/bcom/round-img.png"
                    }
                  />
                  <div className="play-btn-main">
                    <img
                      onClick={handleShowone}
                      className="play-btn-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Courses/bcom/play-button.png"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              {data?.map((image) => (
                <div className="text-holder-online-bcom">
                  <h1>{image?.name}</h1>
                  <p>{parse(image?.description) || ""}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='modal-btn-div text-end'>
            <button className='modal-apply-now' onClick={handleShow}>Apply Now</button>
          </div>
        </div>
      </section>
      {/* video modal */}
      <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Become A Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BecomePartner />
        </Modal.Body>
      </Modal>
      <Modal
        className="modal-video"
        show={showone}
        onHide={handleCloseone}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {data?.map((image) => (
            <div className="round-video">
              <video
                className="online-video"
                playsInline
                autoPlay
                mute
                controls
              >
                <source src={IMG_URL + image?.video}></source>
              </video>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OnlineBcom;
