import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import "./who-qualified.css";
import parse from "html-react-parser";

const Who_qualified = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bca/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);

  const bcawhoqualidata = [
    {
      icon: "/assets/images/Courses/bca/icons/academic_qualitfication.png",
      title1: "Academic",
      title2: "Qualifications",
      description:
        "Secure your spot by holding a relevant diploma or a higher secondary education certificate.",
    },
    {
      icon: "/assets/images/Courses/bca/icons/Professional_Experience.png",
      title1: "Professional ",
      title2: "Experience",
      description:
        "Elevate your learning journey with prior work experience in the IT realm, enriching your coursework with practical insights.",
    },
    {
      icon: "/assets/images/Courses/bca/icons/Technological_Proficiency.png",
      title1: "Technological ",
      title2: "Proficiency",
      description:
        "Leverage your tech-savvy prowess to navigate digital platforms effortlessly, ensuring a smooth online learning experience.",
    },
    {
      icon: "/assets/images/Courses/bca/icons/time_management.png",
      title1: "Strong Time",
      title2: "Management Skills",
      description:
        "Excel in the virtual classroom by demonst-rating impeccable time management, and balancing work commitments with academic responsibilities.",
    },
    {
      icon: "/assets/images/Courses/bca/icons/negotiation.png",
      title1: "Effective",
      title2: "Communication Skills",
      description:
        "Navigate online discussions and collabor-ative projects seamlessly, showcasing your ability to articulate ideas coherently.",
    },
  ];

  return (
    <>
      <section className="bca-who-qualification-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="sec-1">
                <div className="bca-title-div">
                  <h1 className="title who-title">
                    Who Qualified For Online BCA?
                  </h1>
                  <p className="subtitle">
                    Invest in your career with an online BCA, tailored for the
                    dynamic needs of working professionals.
                  </p>
                </div>

                <div className="row mt-4">
                  {data?.map((d, index) => (
                    <div className="col-lg-4 col-sm-6 col-12 mb-4 ">
                      <div className="quali-card-div">
                        <div className="d-flex">
                          <div className="icon-div">
                            <img className="iconss" src={IMG_URL + d?.image} />
                          </div>
                          <div className="title-div">
                            <h6 className="title card-name">{d?.name}</h6>
                            {/* <h6 className='title card-name'>{d?.title2}</h6> */}
                          </div>
                        </div>
                        <div className="discription-div">
                          <p className="content">
                            {parse(d?.description || "")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="sec-2">
                <img
                  className="girl-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/Courses/bca/girl.png"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Who_qualified;
