import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import "./VideoDiploma.css";
import parse from "html-react-parser";

const VideoDiploma = () => {
  const cardData = [
    {
      title: "Prioritize Vocational Training",
      description: "Emphasize skill enhancement for immediate application.",
      imagePath: "/assets/images/Courses/diploma/Icon.png",
    },
    {
      title: "Online Diplomas",
      description:
        "Digital certifications through internet-based distance learning programs.",
      imagePath: "/assets/images/Courses/diploma/Icon.png",
    },
    {
      title: "Flexible Learning",
      description:
        "Covering diverse subjects, online diplomas offer specialized knowledge with flexibility.",
      imagePath: "/assets/images/Courses/diploma/Icon.png",
    },
    {
      title: "Ideal for gap students and working professionals",
      description:
        "Pursue education at your own pace through virtual classrooms.",
      imagePath: "/assets/images/Courses/diploma/Icon.png",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [dataVideo, setDataVideo] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/advantages`
      );
      setData(response?.data)
      const response1 = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/video`
      );
      setDataVideo(response1?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="video-diploma">
        <div className="container">
          <div className="row">
          {dataVideo?.map((val)=>
            <div className="col-lg-5 ">
              
              <div className="video-div ">
                <video
                  className="online-video"
                  playsInline
                  autoPlay
                  // mute
                  controls
                >
                  <source
                    src={
                     IMG_URL +
                      val?.video
                    }
                  ></source>
                </video>
              </div>
             
            </div>
             )}

            <div className="col-lg-7 mt-2">
              <div className="row certificatioCard mt-5">
                {data?.map((card, index) => (
                  <div
                    key={index}
                    className={`col-md-6 col-sm-6 ${
                      index % 2 === 0 ? "mb-5" : "mt-4 mb-md-0 mb-sm-0 mb-5"
                    }`}
                  >
                    <div className="main-card">
                      <div className="card-content">
                        {card.name ? (
                          <p>
                            {card.name}: <span>{parse(card.description)}</span>
                          </p>
                        ) : (
                          <span className="text-center">
                            {parse(card.description)}
                          </span>
                        )}
                      </div>
                      <div className="icon-div">
                        <img
                          className="eduicon"
                          src={IMG_URL + card?.image}
                          alt={card.title}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoDiploma;
