import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './McomReason.css'

function McomReason() {
    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/m-com/resons`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);
    return (
        <>
            <section className='mcom-reason-section'>
                <div className='container'>
                    <div className='heading'>
                        <h5 className='title'>
                            Top Reasons to Choose Online M.Com.
                        </h5>
                    </div>
                    <div className='mba-reason-cards'>
                        <div className='row'>
                            {data?.map((val)=>
                            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='sub-main-img-class'>
                                    <div className='d-flex main-card-div'>
                                        <div className='img-div'>
                                            <img className='reason-icon' src={IMG_URL + val.image} />
                                        </div>
                                        <div className='card-content'>
                                            <h6>{val.title}</h6>
                                            <p>{parse(val?.description) || ""}</p>
                                        </div>
                                        <div className='orange-overlay'></div>
                                    </div>
                                </div>
                            </div>
                            )}
                           
                        </div>
                    </div>
                    <div className='bottom-heading'>
                        <h5>Invest in your future with the convenience and versatility of Online M.Com, designed for success in the modern professional landscape.</h5>
                    </div>
                </div>
            </section>
        </>
    )
}

export default McomReason