import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../utils/context";
import './ProgrameHighlight.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/grid';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerThumbnail } from 'react-shimmer-effects';

// import { Navigation, Pagination } from "swiper/modules";

const ProgrameHighlight = ({ id }) => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/specialization/highlights/${id}`);
        setData(response?.data);
    };

    useEffect(() => {
        getDataAll();
        console.log(data);
    }, [id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ? (
                <Container>
                    <ShimmerThumbnail height={250} rounded />
                </Container>
            ) : (
                data && data.length > 0 && (
                    <section className='programe-highlight'>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className='heading-holder text-center'>
                                        <h2>Programme Highlights</h2>
                                        <div className='border-line'></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Swiper
                                    spaceBetween={30}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        420: {
                                            slidesPerView: 1.5,
                                            spaceBetween: 20,
                                        },
                                        486: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        1000: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        1200: {
                                            slidesPerView: 4,
                                            spaceBetween: 30,
                                        },
                                        1400: {
                                            slidesPerView: 5,
                                            spaceBetween: 30,
                                        },
                                        1900: {
                                            slidesPerView: 5,
                                            spaceBetween: 30,
                                        },
                                    }}
                                    className="mySwiper"
                                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                                >
                                    {data.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="main-section mb-5">
                                                <img src={IMG_URL + item.image} className="all-image" alt="" />
                                                <div className="text-holder">
                                                    <p className="">{item?.name}</p>
                                                </div>
                                                <div className="bg-overlay"></div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Row>

                            <div className="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick("prev")}
                                ></div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick("next")}
                                ></div>
                            </div>
                        </Container>
                    </section>
                )
            )}
        </>
    );
}

export default ProgrameHighlight;
