import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../utils/context";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import ImageContext from "../../../../../Utils/MyContext/MyContext";
const DiplomaBanner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/certificat/diploma/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="BBA_banner contact-banner">
        <div className="container">
          <div className="image-div desktop-view-banner">
            {/* {name.map((image) => (
              <img
                key={image.id}
                src={image.image25}
                className="bbabanner-img"
              />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="banner-img1"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="image-div mobile-view-banner">
            {/* {name.map((image) => (
              <img key={image.id} src={image.image35} className="banner-img1" />
            ))} */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="banner-img1"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiplomaBanner;
