import React from 'react'
import Bcombanner from './banner/Bcombanner'
import OnlineBcom from './online-bcom/OnlineBcom'
import FeatureOnline from './feature-online/FeatureOnline'
import BcomReason from './bcom-reason/BcomReason'
import QualificationBcom from './qualification-bcom/QualificationBcom'
import DiscoverPower from './discover-power/DiscoverPower'

const Bcom = () => {
  return (
    <>
      <Bcombanner />
      <OnlineBcom />
      <FeatureOnline />
      <BcomReason />
      <DiscoverPower/>
      <QualificationBcom />
    </>
  )
}

export default Bcom