import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "../advantage-university/AdvantageUniversity.css";
import parse from "html-react-parser";

const AdvantageUniversity = () => {
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/university/dy-patil/advantages`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className="advantage-university">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 text-center">
              <div className="title-advantage-university">
                <h3 className="title">
                  {" "}
                  Advantages of taking admission from DPU COL (PUNE)
                </h3>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5 justify-content-center">
            {data?.map((val, index) =>
              index % 2 === 0 ? (
                <div
                  className="col-xxl-2 col-xl-4 col-lg-4 col-md-12 text-center"
                  key={index}
                >
                  <div className="advantage-title">
                    <h5>{val?.name}</h5>
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/university/dy-patil/university-advantage/Group 22226.png"
                      }
                      className="black-img"
                      alt="..."
                    />
                  </div>
                  <div className="first-orange-box ">
                    <div className="strategising-text">
                      <p>{parse(val?.description)}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="col-xxl-2 col-xl-4 col-lg-4 col-md-12"
                  key={index}
                >
                  <div className="second-orange-box">
                    <div className="strategising-text">
                      <p>{parse(val?.description)}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/university/dy-patil/university-advantage/down.png"
                      }
                      className="black-img"
                      alt="..."
                    />
                  </div>
                  <div className="advantage-title">
                    <h5>{val?.name}</h5>
                  </div>
                </div>
              )
            )}

            {/* <div className='col-xxl-2 col-xl-4 col-lg-4 col-md-12'>
                            <div className='advantage-title'>
                                <h5>Case-Based Learning (CBL)</h5>
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/university-advantage/Group 22226.png"} className="black-img" alt="..." />
                            </div>
                            <div className='third-orange-box'>
                                <div className='strategising-text'>
                                    <p>Learning is of value only when one can apply it in real-world scenarios. CBL is a crucial part of our programs hence promoting higher levels of cognition, versatility, and efficient self- guided learning.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-2 col-xl-4 col-lg-4 col-md-12'>
                            <div className='four-orange-box'>
                                <div className='strategising-text'>
                                    <p>Learners get access to extensive online articles, case studies, and journals so that they can gain in-depth knowledge of your subjects.</p>
                                </div>
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/university-advantage/down.png"} className="black-img" alt="..." />
                            </div>
                            <div className='advantage-title'>
                                <h5>Digital Library</h5>
                            </div>
                        </div>
                        <div className='col-xxl-2 col-xl-4 col-lg-4 col-md-12'>
                            <div className='advantage-title'>
                                <h5>Learning Support</h5>
                            </div>
                            <div>
                                <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/university-advantage/Group 22226.png"} className="black-img" alt="..." />
                            </div>
                            <div className='five-orange-box'>
                                <div className='strategising-text'>
                                    <p>We provide learning support that includes program-oriented sessions, academic calendars, live intera- ctive virtual classes, counseling sessions, webinars with industrial experts, and discussion forums.</p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvantageUniversity;
