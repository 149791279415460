import React from "react";
import { useContext } from "react";
import ImageContext from "../../../../Utils/MyContext/MyContext";
import "./ReadThirdBanner.css";

import { NavLink } from "react-router-dom";
function ReadThirdBanner() {
  const { name } = useContext(ImageContext);
  return (
    <>
      <section className="third-banner-section mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12 desktop-view-banner">
              <div className="blogbanner-image">
                {name?.map((image) => (
                  <img
                    key={image.id}
                    src={image.image28}
                    className="blog-img"
                  />
                ))}
                <div className="back-icon-div">
                  <NavLink to="/ed-tech/blog">
                    <img
                      className="back-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Blogs/Third-tab/back.png"
                      }
                    />
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-md-12 mobile-view-banner">
              <div className="blogbanner-image">
                {name?.map((image) => (
                  <img
                    key={image.id}
                    src={image.image34}
                    className="blog-img"
                  />
                ))}
                <div className="back-icon-div">
                  <NavLink to="/ed-tech/blog">
                    <img
                      className="back-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/Blogs/Third-tab/back.png"
                      }
                    />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReadThirdBanner;
