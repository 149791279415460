import React from 'react'
import "./AmitySmallBanner.css"
const AmitySmallBanner = () => {
    return (
        <>
            <section className='amity-small-ban-sec'>
                <div className='container'>
                    <img className='amitybannn-img' src={process.env.PUBLIC_URL + '/assets/images/university/AmeityUniversity/Group 22507.png'} />
                </div>
            </section>
        </>
    )
}

export default AmitySmallBanner