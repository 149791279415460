import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import Container from "react-bootstrap/Container";
import "./BhartiFeature.css";
import parse from "html-react-parser";

const BhartiFeature = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/bharati-vidyapeeth/program-features`
    );
    await setData(response?.data);
  };
  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="bharti-features-section">
        <Container>
          <div>
            <div className="heading">
              <h4 className="title">Key Program Features</h4>
              <div className="text-border" />
              <p>
                An enriching learning experience should be engaging and
                stimulating.
              </p>
            </div>
            <div className="cards-sec">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4   col-12">
                  {data?.map((item, index) => (
                    <div className="complete-card">
                      <div className="main-card">
                        <h3>{item?.name}</h3>
                        <p>{parse(item?.description)}</p>
                      </div>
                      <div className="upper-small-card">
                        <div className="feature-images">
                          <img
                            className="main-img"
                            src={IMG_URL + item?.image}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BhartiFeature;
