import React from 'react'
import './McomsmallBan.css'

function McomsmallBan() {
    
    return (
        <>
            <section className='mcom-small-banner-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='desktopview-small-banner'>
                            <img className='mcom-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/small-ban.png"} />
                        </div>

                        <div className='mobileview-small-banner'>
                            <img className='mcom-ban-img' src={process.env.PUBLIC_URL + "/assets/images/Mobile-Banner/Banner/M.Com.png"} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default McomsmallBan