import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import "./OnlineBanner.css"
import { Modal } from "react-bootstrap";
import BecomePartner from '../../../../become-partner/BecomePartner';
const OnlineBanner = () => {
    // const { name } = useContext(ImageContext);

    // become partner modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/pg-courses/ma/what`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='Online_banner'>
                <div className='container'>
                    {data?.map((val) =>
                        <div className='row'>

                            <div className='col-lg-8 col-md-8 my-auto'>
                                <div className='OnlinebanHeadtext'>
                                    <h3>{val.title}</h3>
                                    <p>{parse(val?.description) || ""}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 my-auto'>
                                <div className='OnlinebaneImgmain'>
                                    <img src={IMG_URL + val.image} className='Onlineban1' />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='modal-btn-div'>
                        <button className='modal-apply-now' onClick={handleShow}>Apply Now</button>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Become A Partner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BecomePartner />
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default OnlineBanner