import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import "./programoffered.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import parse from "html-react-parser";
import { Pagination } from "swiper/modules";

const Programoffered = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/bharati-vidyapeeth/program-offered`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const programofferedslider = [
    {
      coursename: "MBA",
      content:
        "Master of Business Administration (MBA), is the best and highly-valued professional",
      image:
        "/assets/images/ed-tech/university/bharati-vidyapeeth/program-offered/MBA.png",
    },
    {
      coursename: "BBA",
      content:
        "BBA is a 3-year undergraduate course that deals with providing students knowledge regarding commerce",
      image:
        "/assets/images/ed-tech/university/bharati-vidyapeeth/program-offered/BBA.png",
    },
    {
      coursename: "BCA",
      content:
        "BCA Degree: 3-Year/4-Year Honors Program with 120/160 Credits, Online Learning.",
      image:
        "/assets/images/ed-tech/university/bharati-vidyapeeth/program-offered/BCA.png",
    },
    {
      coursename: "MCA",
      content: "Explore accredited MCA program online, recognized by AICTE.",
      image:
        "/assets/images/ed-tech/university/bharati-vidyapeeth/program-offered/MCA.png",
    },
  ];

  return (
    <>
      <section className="Programoffered-section">
        <div className="container">
          <div className="title-sec">
            <h3 className="title abt-title">Program Offered</h3>
            <div className="underline"></div>
            <p className="sub-title">
              Explore Bharati Vidyapeeth’s online programs for diverse
              educational opportunities.
            </p>
          </div>

          <div className="prog-off-slider-sec">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((data, index) => (
                <SwiperSlide>
                  <div className="slider-main-div mb-5">
                    <h2 className="course-name">{data?.name}</h2>
                    <p className="couse-content">{parse(data?.description)}</p>

                    <img className="overlay-img" src={IMG_URL + data?.image} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Programoffered;
