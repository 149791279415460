import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "react-bootstrap/Container";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./BhartiAdvantage.css";
import parse from "html-react-parser";

const BhartiAdvantage = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/bharati-vidyapeeth/advantages`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  const AdvantageDetails = [
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/learning.png",
      heading: "Flexible Learning",
      descriptn:
        "Access the complete online course content anytime, anywhere, allowing personalized learning at your own pace. Access the complete online course content anytime, anywhere, allowing personalized learning at your own pace.",
    },
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/expert.png",
      heading: "Expert Faculties/Industry Experience",
      descriptn:
        "Benefit from online classes and guidance by experienced faculties with a strong background in the industry.",
    },
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/faculty.png",
      heading: "Faculty Interaction",
      descriptn:
        "Engage in subject-related faculty interaction through an online discussion forum to clarify doubts and queries. Practical Teaching. Utilize a practical teaching approach with access to the latest domestic application.",
    },
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/placement.png",
      heading: "Placement & Assistance",
      descriptn:
        "Gain access to job openings and receive support in building a resume profile for better placement opportunities.",
    },
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/assis.png",
      heading: "Faculty Interaction",
      descriptn:
        "Engage in subject-related faculty interaction through an online discussion forum to clarify doubts and queries. Practical Teaching. Utilize a practical teaching approach with access to the latest domestic application.",
    },
    {
      imgsource:
        process.env.PUBLIC_URL +
        "/assets/images/university/bharati-vidyapeeth/advantage/workshop.png",
      heading: "Workshops",
      descriptn:
        "Experience a curriculum designed to foster leadership and entrepreneurship skills, complemented by regular workshops and boot camps on industry-relevant topics.",
    },
  ];
  return (
    <>
      <section className="bharti-advantages-section">
        <Container>
          <div className="heading text-center">
            <h1 className="title">
              Advantages of taking admission in BHARATI VIDYAPEETH
            </h1>
          </div>

          <div className="bharti-adv-swiper">
            <Swiper
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 4,
                },
              }}
              className="mySwiper px-2"
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="cards-div">
                    <div className="main-card-adv">
                      <h5 className="title">{item?.name}</h5>
                      <p>{parse(item?.description)}</p>
                    </div>
                    <div className="img-div">
                      <div className="circle">
                        {item?.image && (
                          <img
                            className="main-img"
                            src={IMG_URL + item?.image}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BhartiAdvantage;
