import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import { Row, Col, Container } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import "../online-blib/onlineblib.css";
import parse from "html-react-parser";
import { Modal } from "react-bootstrap";
import BecomePartner from '../../../../become-partner/BecomePartner';

const OnlineBlib = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-lib/what`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="onlinebsc-section">
        <div className="onlinebsc-main">
          <div className="online-part1">
            <Container>
              <div className="part1-main">
                <Row>
                  <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
                    {data?.map((item) => (
                      <div className="text-holder-online-blib">
                        <h1 className="title">What is Online B.Lib ?</h1>
                        <p>{parse(item?.description || "")}</p>
                        {/* <p>Embark on a journey of knowledge with B.Lib. Online, a program specially crafted for distance learners, aspiring librarians, and individuals working in diverse fields. Our SEO-friendly curriculum empowers you to thrive in a virtual learning environment, offering interactive modules, industry connections, and career advancement opportunities. Embrace the convenience of online learning and unlock a world of possibilities with B.Lib. Online. Enroll today to shape your path to success from any location.</p> */}
                      </div>
                    ))}
                  </Col>
                  <Col
                    xxl={7}
                    xl={7}
                    lg={7}
                    md={12}
                    sm={12}
                    xs={12}
                    className="my-auto"
                  >
                    {data?.map((item) => (
                      <div className="video-column">
                        <LazyLoad>
                          <video
                            className="bscvideo"
                            controls
                            autoPlay
                            loop
                            mute
                          >
                            <source src={IMG_URL + item?.video}></source>
                          </video>
                        </LazyLoad>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>

              <div className='modal-btn-div'>
                <button className='modal-apply-now' onClick={handleShow}>Apply Now</button>
              </div>
            </Container>
            <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
              <Modal.Header closeButton>
                <Modal.Title>Become A Partner</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BecomePartner />
              </Modal.Body>
            </Modal>
            <div className="bgcolr"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OnlineBlib;
