import { React, useRef, useEffect, useState, useContext } from "react";
import ImageContext from '../../../../../Utils/MyContext/MyContext';
import { Context } from "../../../../../../utils/context";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";
import "./OnlineMba.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import BecomePartner from "../../../../become-partner/BecomePartner";

const OnlineMba = () => {
  // become partner modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mba/what`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  const { online } = useContext(ImageContext);
  return (
    <>
      <section className='ChooseOnBanner'>
        <div className='container'>
          {data?.map((val) => (
            <div className='row mb-5'>
              <div className='col-lg-6 col-md-6'>

                <div className='chooseheadingpara mt-4'>
                  <h3>{val.title}</h3>
                  <p><b>{parse(val?.description) || ""}</b></p>

                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
                <div class="gallery">
                  <div class="image1">
                    <img src={IMG_URL + val.image1} alt="Image 1" className='chooseimg_1' />
                  </div>
                  <div class="image">
                    <img src={IMG_URL + val.image2} alt="Image 1" className='chooseimg_1' />
                  </div>
                  <div className='rectangleOverlay'></div>
                </div>
              </div>
            </div>
          ))}
          <div className='modal-btn-div'>
            <button className='modal-apply-now' onClick={handleShow}>Apply Now</button>
          </div>
        </div>
        <div >
          <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
            <Modal.Header closeButton>
              <Modal.Title>Become A Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BecomePartner />
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  )
}

export default OnlineMba