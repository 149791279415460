import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import { Row, Col, Container } from "react-bootstrap";
import "./Qualifiedblib.css";
import parse from "html-react-parser";

const qualifications = [
  {
    imgSrc: process.env.PUBLIC_URL + "/assets/images/Courses/blib/q1.png",
    title: "Academic Qualifications",
    description:
      "Enhance your expertise with a comprehensive library science program online, designed for individuals with a solid academic foundation.",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/assets/images/Courses/blib/q2.png",
    title: "Professional Experience",
    description:
      "Leverage your work background while pursuing an online B.Lib, integrating theoretical knowledge with practical experience for a well-rounded learning journey.",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/assets/images/Courses/blib/q3.png",
    title: "Strong Time Management Skills",
    description:
      "Our flexible online platform accommodates your schedule, enabling you to master advanced concepts while maintaining a balance with your professional commitments.",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/assets/images/Courses/blib/q4.png",
    title: "Effective Communication Skills",
    description:
      "Hone your communication prowess through interactive online sessions, collaborative projects, and engaging discussions, preparing you for leadership roles.",
  },
  {
    imgSrc: process.env.PUBLIC_URL + "/assets/images/Courses/blib/q5.png",
    title: "Technological Proficiency",
    description:
      "Stay ahead in the digital age with cutting-edge tools and technology embedded in our online B.Lib program, ensuring you graduate with a tech-savvy edge.",
  },
];

const QualifiedBlib = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/b-lib/who-qualified`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="qualifiedblib-section">
        <Container>
          <div className="text-holder-qualified">
            <h2>Who Qualified For Online B.Lib.?</h2>
            <Col xl={6} className="mx-auto">
              <p>
                Are you a working professional aspiring to earn your B.Lib
                degree online? Seize new opportunities with the right
                qualifications. Ensure
              </p>
            </Col>
            <p>Earn Your B.Lib Online with Academic Excellence</p>
          </div>
          <Row>
            {data?.map((d, index) => (
              <Col lg={4} md={6} key={index}>
                <div className="qualified-main-img">
                  <img
                    src={IMG_URL + d?.image}
                    className="qualified-img"
                    alt="..."
                  />
                  <div className="orange-overlay">
                    <h2>{d?.name}</h2>
                  </div>
                  <div className="hover-orange-overlay">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/Courses/blib/${index + 1}.png`
                      }
                      className="qualified-hover-img"
                      alt="..."
                    />
                    <h2>{d?.name}</h2>
                    <p>{parse(d?.description)}</p>
                  </div>
                  <div className="bggg-clll"></div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default QualifiedBlib;
