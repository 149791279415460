import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import Banner from './banner/Banner'
import Aboutdpu from './about-dpu/Aboutdpu'
import Nowait from './Nowait/Nowait'
import Questions from './Questions/Questions'
import Wedeliver from './we-deliver/Wedeliver'
import ProgrammOffered from './programm-offered/ProgrammOffered'
import Drdybanner from './dr-dy-banner/Drdybanner'
import Testimonial from './Testimonial/Testimonial'
import Benifits from './Benifits/Benifits'
import Dypartners from './Dypartners/Dypartners'
import ProgrammFeatured from './programm-featured/ProgrammFeatured'
import AdvantageUniversity from './advantage-university/AdvantageUniversity';
import Achievement from './Achievement/Achievement';
import InternationalCourse from './International-course/InternationalCourse';
// import { MyContext } from '../../../Utils/MyContext/MyContext';
import KeyPrograme from "../common-university/Key-programe/KeyPrograme";
import BhartiAdvantage from "../common-university/BhartiAvtantage/BhartiAdvantage"

import { useParams } from 'react-router-dom';
const Dypatil = () => {
  const { id } = useParams();
  console.log(id, "id id id");

  const { getData } = useContext(Context);
  const [data, setData] = useState({});
  const [universityName, setUniversityName] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/university/common/university/${id}`
    );
    await setData(response?.data);
    await setUniversityName(response?.data?.name);


  };

  useEffect(() => {
    getDataAll(id);
    console.log("data from main ", data);
    console.log(universityName);
  }, [id]);
  return (
    <>
      <Banner id={id} universityName={universityName} />
      <Aboutdpu id={id} universityName={universityName} />
      <Wedeliver id={id} universityName={universityName} />
      <ProgrammOffered id={id} universityName={universityName} />
      <InternationalCourse id={id} universityName={universityName} />

      {/* <ProgrammFeatured id={id} universityName={universityName} /> */}

      < KeyPrograme id={id} universityName={universityName} />

      <Drdybanner id={id} universityName={universityName} />

      {/* <AdvantageUniversity id={id} universityName={universityName} /> */}

      < BhartiAdvantage id={id} universityName={universityName} />

      <Dypartners id={id} universityName={universityName} />
      <Benifits id={id} universityName={universityName} />
      <Testimonial id={id} universityName={universityName} />
      <Achievement id={id} universityName={universityName} />
      <Questions id={id} universityName={universityName} />
      <Nowait id={id} universityName={universityName} />
    </>
  )
}

export default Dypatil