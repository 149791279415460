import React from 'react'
import "./Enrollbba.css"
const EnrollBBA = () => {
    return (
        <>
            <section className='Enrollbba'>
                <div className='container-fluid p-0'>
             

                        <div className='slider-section'>

                            <div className='banner-slide-div'>
                                <div className='image-div'>
                                    <img className='enrollbanner-img' src={process.env.PUBLIC_URL + "/assets/images/Courses/Enroll_ban.png"} />
                                </div>
                                <div className='banner-content-div'>
                                    <p className='bannerprop-title title'>Enroll today and unlock a transformative educational journey  that aligns with your aspirations in the world of business.</p>

                                    {/* <p className='subcontent'>{sliderimage.subcontent}</p> */}
                                </div>
                            </div>
                        </div>

                  
                </div>
            </section>
        </>
    )
}

export default EnrollBBA