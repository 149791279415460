import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './QualifiedMcom.css'

function QualifiedMcom() {
    const { getData, IMG_URL } = useContext(Context);
  
    const [data, setData] = useState([]);
  
    const getDataAll = async () => {
      try {
        const response = await getData(`/without-login/ed-tech/courses/pg-courses/m-com/who-qualified`);
        setData(response?.data);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
   console.log(data);
  
    useEffect(() => {
      getDataAll();
    }, []);
    return (
        <>
            <section className='mcom-qualified-section'>

                <div className='container'>
                    <div className='main-heading'>
                        <h1 className='title'>Who Qualified For Online M.Com.?</h1>
                        <p>Welcome to our Online M.Com Program Tailored for Working Professionals</p>
                    </div>

                    {/* <div className='row'>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                            <div className='qualified-card'>
                                <div className='icon-bg'>
                                    <img className='qualified-icon' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/academic.png"} />
                                </div>
                                <div className='card-text'>
                                    <h4>Academic Qualifications</h4>
                                    <p>Elevate your career with our Online M.Com. designed for working professionals. To qualify, applicants need a bachelor's degree from an accredited institution. Diverse academic backgrounds are welcomed.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                            <div className='qualified-card'>
                                <div className='icon-bg'>
                                    <img className='qualified-icon' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/Professional.png"} />
                                </div>
                                <div className='card-text'>
                                    <h4>Professional Experience</h4>
                                    <p>Built for those with a vision for success, our program values professional experience. A minimum of a few years in the workforce is preferred, ensuring a dynamic blend of theoretical knowledge and practical insights.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mx-auto'>
                            <div className='qualified-card'>
                                <div className='icon-bg'>
                                    <img className='qualified-icon' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/Technological.png"} />
                                </div>
                                <div className='card-text'>
                                    <h4>Strong Time Management Skills</h4>
                                    <p>Master the art of balancing work, family, and academics. Our online platform demands strong time management skills, empowering professionals to excel in their studies without compromising on their current commitments.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='row justify-content-center'>
                        {data?.map((val)=>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                            <div className='qualified-card'>
                                <div className='icon-bg'>
                                    <img className='qualified-icon' src={IMG_URL+val.image} />
                                </div>
                                <div className='card-text'>
                                    <h4>{val.title}</h4>
                                    <p>{parse(val?.description) || ""}</p>
                                </div>
                            </div>
                        </div>
                        )}
                        {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12'>
                            <div className='qualified-card'>
                                <div className='icon-bg'>
                                    <img className='qualified-icon' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/negotiation.png"} />
                                </div>
                                <div className='card-text'>
                                    <h4>Technological Proficiency</h4>
                                    <p>Navigate the digital landscape with ease. Our online M.Com. program requires technological proficiency, ensuring a seamless learning experience through virtual classes, collaborative projects, and online assessments.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

            </section>

        </>
    )
}

export default QualifiedMcom