import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";

import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import "./Testimonial.css";

const Testimonial = () => {
    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/university/dy-patil/alumni-image`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='testimonial-section'>
                <Container>

                    <div className='heading'>
                        <h4 className='title'>Alumni Testimonials</h4>
                        <div className='text-border' />
                        <p>which will be helpful in your counselling.</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-9 col-md-9'>
                            <div className='swiper-content'>
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    {data?.map((val)=>
                                    <SwiperSlide>
                                        <div className='swiper-video-div'>
                                            <video className='videos' playsInline autoPlay mute controls >
                                                <source src={IMG_URL+val?.video}></source>
                                            </video>
                                        </div>
                                    </SwiperSlide>
                                    )}
                                    {/* <SwiperSlide>
                                        <div className='swiper-video-div'>
                                            <video className='videos' playsInline autoPlay mute controls >
                                                <source src={process.env.PUBLIC_URL + '/assets/videos/home/who-we-are/bgvideo.mp4'}></source>
                                            </video>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiper-video-div'>
                                            <video className='videos' playsInline autoPlay mute controls >
                                                <source src={process.env.PUBLIC_URL + '/assets/videos/home/who-we-are/bgvideo.mp4'}></source>
                                            </video>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiper-video-div'>
                                            <video className='videos' playsInline autoPlay mute controls >
                                                <source src={process.env.PUBLIC_URL + '/assets/videos/home/who-we-are/bgvideo.mp4'}></source>
                                            </video>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='swiper-video-div'>
                                            <video className='videos' playsInline autoPlay mute controls >
                                                <source src={process.env.PUBLIC_URL + '/assets/videos/home/who-we-are/bgvideo.mp4'}></source>
                                            </video>
                                        </div>
                                    </SwiperSlide> */}

                                </Swiper>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-3'>
                            <div className='right-swiper-div'>
                            <Swiper
                                direction={'vertical'}
                               
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                    loop: true,
                                }}


                                loop="true"
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                            >
                                {data?.map((val)=>
                                <SwiperSlide>
                                    <div className='bvUniversityImgMain'>
                                        <img src={IMG_URL+val?.image} className='bvUniversityImg1' />
                                    </div>
                                </SwiperSlide>
                                )}
                                {/* <SwiperSlide>
                                    <div className='bvUniversityImgMain'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/banner/side-ban-2.png"} className='bvUniversityImg1' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bvUniversityImgMain'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/banner/side-bann1.png"} className='bvUniversityImg1' />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='bvUniversityImgMain'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/university/dy-patil/banner/side-ban-2.png"} className='bvUniversityImg1' />
                                    </div>
                                </SwiperSlide> */}

                            </Swiper>
                            </div>
                        </div>
                    </div>

                </Container>
            </section>
        </>
    )
}

export default Testimonial