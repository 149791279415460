import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './OnlineMsc.css';
import Modal from 'react-bootstrap/Modal';
import BecomePartner from "../../../../become-partner/BecomePartner";

const OnlineMsc = () => {

    // become partner modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showModal, setShowModal] = useState(false);
    const [videoSrc, setVideoSrc] = useState("");

    // Function to show the modal and set the video source
    const handleShowModal = (videoSrc) => {
        setShowModal(true);
        setVideoSrc(videoSrc);
    };

    // Function to hide the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/pg-courses/msc/what`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='online-msc'>
                <div className='container'>
                    {data?.map((val) =>
                        <div className='row'>
                            <div className=' col-lg-6 '>
                                <div className='mx-auto'>
                                    <div className='image-holder text-end '>
                                        <img className='lawyer-img' src={IMG_URL + val?.image} />
                                        <div className='video-holder'>

                                            <img class="online-video" src="/assets/images/Courses/msc/mscVideoImg.png" />
                                            <div class="playBtnMain">
                                                <img class="platBtn" src="/assets/images/Courses/msc/play-button.png" onClick={() => handleShowModal(IMG_URL + val?.video)} />

                                            </div>
                                        </div>
                                        <div className='circle-holder'></div>
                                    </div>
                                </div>
                            </div>
                            <div className=' col-lg-6'>
                                <div className='heading-holder mt-5 ps-md-3 ps-0'>
                                    <h4 className='title mb-3'>{val?.title}</h4>
                                    {/* <p>Explore boundless possibilities with our Online M.Sc program. Tailored for distance learners, entrepreneurs, and working professionals,
                                    our SEO-friendly curriculum empowers you to excel in a flexible virtual environment. Dive into interactive modules, connect with industry experts, and advance your career seamlessly. Elevate your education, embrace convenience, and unlock a world of opportunities with our Online M.Sc program.
                                    Enroll today to chart your path to success from anywhere in the world.</p> */}
                                    <p>{parse(val?.description) || ""}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='modal-btn-div text-end'>
                        <button className='modal-apply-now  ' onClick={handleShow}>Apply Now</button>
                    </div>
                </div>
            </section>


            {/* <Modal className='modal-video' show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='round-video'>
                        <video className='online-video' playsInline autoPlay mute controls >
                            <source src={process.env.PUBLIC_URL + '/assets/videos/home/banner/banner1.mp4'}></source>
                        </video>
                    </div>
                </Modal.Body>
            </Modal> */}

            <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Become A Partner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BecomePartner />
                </Modal.Body>
            </Modal>
            <Modal className='modal-video' show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className='round-video'>
                        <video className='online-video' playsInline autoPlay muted controls>
                            <source src={videoSrc}></source>
                        </video>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default OnlineMsc