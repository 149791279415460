import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import "./MscBanner.css";
import ImageContext from "../../../../../Utils/MyContext/MyContext";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const MscBanner = () => {
  const { name } = useContext(ImageContext);

  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/pg-courses/msc/banner`
      );
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);
  return (
    <>
      <section className="mscbanner">
        <div className="container">
          <div className="bca-banner-div desktop-view-banner">
            {/* <div className='image-div pt-4'>
                            {name.map((image) => (
                                <img key={image.id} src={image.image18} className='bca-img' />
                            ))}
                        </div> */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="bca-banner-div mobile-view-banner">
            {/* <div className='image-div pt-4'>
                            {name.map((image) => (
                                <img key={image.id} src={image.image46} className='bca-img' />
                            ))}
                        </div> */}
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <div className="image-div">
                    <img
                      key={image.id}
                      src={IMG_URL + image?.image}
                      className="bca-img"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default MscBanner;
