import React from 'react'
import "./InternationalCourse.css"
const InternationalCourse = () => {
  return (
    <>
      <section className='AmityInternational'>
        <div className='container'>
          <div className='internationalheadtxt'>
            <h3>Amity University</h3>
            <div class="border-line mb-3"></div>
            <p>Offers Online MBA Degree + Value added international courses</p>
          </div>

          <div className='mb-lg-4 mb-md-0 mb-0'>
            <img src={process.env.PUBLIC_URL + "/assets/images/Amity-vniversity/sub-banner.png"} className='internationalbanner' />
          </div>
        </div>
      </section>
    </>
  )
}

export default InternationalCourse