import { React, useRef, useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import { Context } from "../../../../../../utils/context";
import './Onlinemcom.css'
import BecomePartner from "../../../../become-partner/BecomePartner";
import { Modal } from "react-bootstrap";

function Onlinemcom() {
    const { getData, IMG_URL } = useContext(Context);
    // become partner modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/courses/pg-courses/m-com/what`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            <section className='online-mcom-section'>
                <div className='main-onlin-mcom-sec p-0'>
                    <div className='onlin-img-div '>
                        <img className='onlin-mcom-banner' src={process.env.PUBLIC_URL + "/assets/images/Courses/mcom/onlinemcom.png"} />
                    </div>

                    {data?.map((val) =>
                        <div className='online-content-mcom'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
                                        <div className='descrptn'>
                                            <h5 className='title'>{val.title}</h5>
                                            <p>{parse(val?.description) || ""} </p>
                                        </div>
                                    </div>
                                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                                        <div className='video-div'>
                                            <video className='online-video' playsInline autoPlay mute controls >
                                                <source src={IMG_URL + val.video}></source>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='modal-btn-div'>
                        <button className='modal-apply-now  ms-2' onClick={handleShow}>Apply Now</button>
                    </div>

                </div>
                <Modal show={show} onHide={handleClose} animation={false} centered className='become-partner-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Become A Partner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BecomePartner />
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default Onlinemcom