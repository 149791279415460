import { React, useRef, useEffect, useState, useContext } from "react";
import './ReasonMca.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Context } from "../../../../../../../utils/context";
import parse from "html-react-parser";
function ReasonMca() {
    const slidesData = [
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/ic1.png",
            title: "Adaptable Scheduling",
            description: "Craft your study timetable around your commitments."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/ic2.png",
            title: "Budget-Friendly",
            description: "Affordable education that doesn't compromise on quality."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/ic3.png",
            title: "Enhanced Time Management",
            description: "Juggle work, life, and education seamlessly."
        },
        {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/ic4.png",
            title: "Increased Course Variety",
            description: "Explore diverse modules, catering to your specific interests and career goals."
        },
         {
            iconSrc: process.env.PUBLIC_URL + "/assets/images/Courses/mca/ic5.png",
            title: "Career Advancement Opportunities",
            description: "Elevate your professional standing with advanced skills and qualifications."
        },
        
    ];

    const { getData, IMG_URL } = useContext(Context);
  
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/pg-courses/mca/resons`);
      setData(response?.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

    return (
        <>
            <section className='Mca-reason-section'>
                <div className='container'>
                    <div className='heading'>
                        <h4 className='title'>Top Reasons to Choose Online MCA</h4>
                    </div>
                    <Swiper
            
                        spaceBetween={5}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            0: { slidesPerView: 1 },
                            360: { slidesPerView: 1 },
                            576: { slidesPerView: 2 },
                            640: { slidesPerView: 2 },
                            768: { slidesPerView: 2 },
                            992: { slidesPerView: 3 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 4 },
                            1800: { slidesPerView: 4 },
                            2500: { slidesPerView: 4 },
                          }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data?.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className='mca-reason-card'>
                                    <div className='first-half-circle' />
                                    <div className='sec-half-circle' />
                                    <div className='icons-div'>
                                        <img className='main-icon' src={IMG_URL+slide.image} alt="icon" />
                                    </div>
                                    <div className='upper-sec'>
                                        <h5>{slide.title}</h5>
                                    </div>
                                    <div className='lower-sec'>
                                        <p>{parse(slide?.description) || ""}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default ReasonMca;
