import React from 'react'
import Mcombanner from './mcombanner/Mcombanner'
import Onlinemcom from './onlinemcom/Onlinemcom'
import Cardsmcom from './cardsmcom/Cardsmcom'
import McomsmallBan from './mcomSmallBan/McomsmallBan'
import McomReason from './McomReason/McomReason'
import QualifiedMcom from './QualifiedMcom/QualifiedMcom'
import DiscoverMcom from './DiscoverMcom/DiscoverMcom'

const Mcom = () => {
  return (
    <>
    
    <Mcombanner/>
    <Onlinemcom/>
    <Cardsmcom/>
    <McomsmallBan/>
    <McomReason/>
    {/* <DiscoverMcom/> */}
    <QualifiedMcom/>
    
    </>
  )
}

export default Mcom