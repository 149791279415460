import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../../../utils/context";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "./BcaDifference.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import parse from "html-react-parser";
const BcaDifference = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/courses/ug-courses/bca/discover`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
    console.log(data);
  }, []);
  return (
    <>
      <section className="BcaDifference">
        <div className="container">
          <div className="row">
            <div className="bcadiffHead">
              <h3>Discover the Power of Online BCA </h3>
              <p>
                Welcome to Our Online BCA Program: Where Flexibility Meets
                Innovation
              </p>
            </div>

            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              breakpoints={{
                575: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
              className="mySwiper"
            >
              {data?.map((slide, index) => (
                <SwiperSlide>
                  <Card className="bcaDiffCard">
                    <Card.Img variant="top" src={IMG_URL + slide?.image} />
                    <Card.Body>
                      <Card.Title>{slide?.name}</Card.Title>
                      <div className="card-wrapper">
                        <div className="reveal-details differencecardText">
                          <p>
                            {parse(slide?.description) || ""}
                            {/* Craft your study schedule around your work commitments. Our flexible approach ensures you control your learning pace and environment. */}
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}

              {/* <SwiperSlide>

                                <Card className='bcaDiffCard'>
                                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assets/images/Courses/DifferenceImg2.png"} />
                                    <Card.Body>
                                        <Card.Title>Work-Integrated Learning</Card.Title>
                                        <div className="card-wrapper">
                                            <div className="reveal-details differencecardText">
                                                <p>
                                                    Craft your study schedule around your work commitments. Our flexible approach ensures you control your learning pace and environment.
                                                </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </SwiperSlide>

                            <SwiperSlide>

                                <Card className='bcaDiffCard'>
                                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assets/images/Courses/DifferenceImg3.png"} />
                                    <Card.Body>
                                        <Card.Title>Cost-Effective Solutions</Card.Title>
                                        <div className="card-wrapper">
                                            <div className="reveal-details differencecardText">
                                                <p>
                                                    Craft your study schedule around your work commitments. Our flexible approach ensures you control your learning pace and environment.
                                                </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </SwiperSlide>

                            <SwiperSlide>

                                <Card className='bcaDiffCard'>
                                    <Card.Img variant="top" src={process.env.PUBLIC_URL + "/assets/images/Courses/DifferenceImg1.png"} />
                                    <Card.Body>
                                        <Card.Title>Innovative Technology</Card.Title>
                                        <div className="card-wrapper">
                                            <div className="reveal-details differencecardText">
                                                <p>
                                                    Craft your study schedule around your work commitments. Our flexible approach ensures you control your learning pace and environment.
                                                </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default BcaDifference;
